<template>
    <div v-if="board" class="min-h-screen p-4 text-white text-lg bg-blue">

        <!--<div class="text-sm">
            #{{ board.id }}
            {{userAgent}}
            <div class="text-blue-300">Android: {{ isAndroid }}, iPhone: {{ isIPhone }}, Chrome: {{ isChrome }}, Safari: {{ isSafari }}</div>
        </div>-->

        <div class="py-4">
            <img src="/images/ainou.png" alt="Ainou" class="w-36 mx-auto">
        </div>
        
        <div v-if="clientOk">

            <div class="border border-gray-400 bg-blue-100 text-gray-600 font-semibold rounded-lg p-4">

                <!-- Android -->
                <div v-if="isAndroid">                
                    <div v-if="beforeInstallEvent">
                        <div>1. Trykk på knappen under for å installere appen</div>
                        <div class="flex justify-center my-6 pt-2">
                            <button class="btn bg-green-700 text-xl px-8 py-3 flex items-center shadow-lg" @click="beforeInstallEvent.prompt()">                            
                                <span class="mx-4">Installér appen</span>
                            </button>
                        </div>
                    </div>
                    <div v-else>1. Velg "Legg til på startsiden" eller "Installér appen" i menyen til nettleseren</div>
                    <!--<div class="fixed top-0 right-0 mr-2 -mt-1">
                        <svg width="60" height="60" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg" class="animate-pulse" fill="#fff">
                            <path d="M1803 1099q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z"/>
                        </svg>
                    </div>-->
                    <div class="mt-2">
                        2. Lukk nettleseren
                    </div>

                    <div class="mt-4 text-base">
                        Appen startes deretter på vanlig måte fra startsiden til telefonen. Ikonet ser slik ut:
                        <div class="mt-4 flex justify-center">                    
                            <img src="/images/app-icon-android.png" alt="App Icon Android" class="rounded-lg">
                        </div>
                    </div>        
                    <div class="mt-2 text-base font-normal">
                        <span class="font-semibold">NB!</span> Dersom du allerede har installert Ainou-appen skal du ikke installere på nytt
                    </div>                             
                </div>

                <!-- iPhone -->
                <div v-else class="leading-snug">                
                    <div>1. Trykk på Del-knappen nederst<!--<img src="images/ios-share.png" alt="" width="18" class="inline -mt-2 mx-2">--></div> 
                    <div class="my-2">2. Velg "Legg til på Hjem-skjermen"</div>
                    <div>3. Lukk nettleseren</div>

                    <div class="mt-2 text-base">
                        Appen startes deretter på vanlig måte fra hjem-skjermen til telefonen. Ikonet ser slik ut:
                    </div>
                    <div class="mt-4 flex justify-center">                    
                        <img src="/images/app-icon-ios.png" alt="App Icon iOS" class="rounded-lg">
                    </div>       
                    <div class="mt-2 text-base font-normal">
                        <span class="font-semibold">NB!</span> Dersom du allerede har installert Ainou-appen skal du ikke installere på nytt
                    </div>         

                    <div v-if="!helpExpanded" class="fixed bottom-0 w-full left-1/2 transform -translate-x-1/2 text-center -mb-3">
                        <div class="mb-3 font-normal w-full text-white">
                            Trykk Del-knappen og velg<br>"Legg til på Hjem-skjermen"
                        </div>
                        <svg width="60" height="60" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg" class="animate-bounce mx-auto" fill="#fff">
                            <path d="M1803 960q0 53-37 90l-651 652q-39 37-91 37-53 0-90-37l-651-652q-38-36-38-90 0-53 38-91l74-75q39-37 91-37 53 0 90 37l294 294v-704q0-52 38-90t90-38h128q52 0 90 38t38 90v704l294-294q37-37 90-37 52 0 91 37l75 75q37 39 37 91z"/>
                        </svg>
                    </div>
                </div>

            </div>

            <!-- Help -->
            <div v-if="screenHeight > 600">
                <details class="mt-4 text-base" id="help" @click="helpClick">
                    <summary>Problemer med å installere?</summary>
                    <p v-if="isAndroid">
                        <ul>
                            <li>Bruk Chrome til å vise denne siden</li>
                            <li>Menyen finner du som regel øverst til høyre (3 prikker)</li>
                        </ul>
                    </p>                           
                    <p v-else>
                        For nyere iPhone kan versjonen av Safari som åpnes etter scanning av QR-koden mangle "Legg til på Hjem-skjermen". 
                        Velg i så fall: Åpne i Safari.
                    </p> 
                    <p class="mt-2 font-semibold">
                        Dersom installasjonen mislykkes trykk <a :href="gameUrl" class="underline whitespace-nowrap">HER</a>                           
                    </p>
                </details>
            </div>

        </div>

        <div v-else class="text-center mt-4">
            Denne siden må åpnes i <span class="font-semibold">{{isAndroid || isWindows ? 'Chrome på mobiltelefon' : 'Safari på iPhone'}}</span>
            <div class="mt-6 text-base text-left">
                Kopier adressen og lim den inn i adressefeltet
                <div class="mt-2 font-mono bg-white px-2 py-1 text-gray-800 text-sm" v-html="currentUrl"></div>
            </div>
        </div>

        
        <!--div v-if="isAndroid && !helpExpanded" class="mb-4 mx-1 text-sm text-blue-300 absolute bottom-0">
            Å installere appen vil gi den beste spill-opplevelsen. Dersom du har problemer med å installere kan du starte spillet 
            <a :href="gameUrl" class="underline whitespace-nowrap">direkte i nettleseren</a>    
        </div-->
        
    </div>
</template>

<script>
import axios from 'axios';

export default {
    
    data() {
        return {
            board: null,
            beforeInstallEvent: null,
            helpExpanded: false,
        }
    },
    created() {
        const urlUuid = this.$route.query.uuid;
        if (!urlUuid) {
            this.showErrorPage();        
        } else {
            this.checkUuid(urlUuid);            
            window.addEventListener('beforeinstallprompt', this.beforeInstallPrompt);
        }
        //console.log(navigator.userAgentData);
    },
    computed: {
        userAgent() {
            return navigator.userAgent;
        },
        currentUrl() {
            return document.location.href;
        },        
        gameUrl() {
            return this.currentUrl.replace('scan', 'game');
        },
        clientOk() {
            return (this.isIPhone && this.isSafari) || (this.isAndroid && this.isChrome);
        },
        isAndroid() {        
            return /android/i.test(this.userAgent);
        },
        isIPhone() {
            return /iphone/i.test(this.userAgent);       
        },
        isWindows() {
            return /windows/i.test(this.userAgent);
        },  
        isSafari() {                     
            return /safari\//i.test(this.userAgent) && !this.isChrome && !this.isEdge;
        },            
        isChrome() {                  
            return (/chrome\//i.test(this.userAgent) || /crios\//i.test(this.userAgent)) && !this.isEdge && !this.isSamsung;
        },                       
        isEdge() {                  
            return /edga\//i.test(this.userAgent) || /edgios\//i.test(this.userAgent) || /edg\//i.test(this.userAgent);
        },
        isSamsung() {
            return /samsungbrowser\//i.test(this.userAgent);
        },
        screenHeight() {
            return window.innerHeight;  //screen.height;
        },
    },
    methods: {
        checkUuid: function(uuid) {
            axios.get('/verifyBoard?uuid=' + uuid).then(res => {                
                this.board = res.data.board;
                this.createManifest();
                this.initServiceWorker();
            }).catch((err) => {
                console.log(err);
                this.showErrorPage();
            });
        },
        showErrorPage: function() {
            this.$router.push({name: 'errorPage'});
        },
        beforeInstallPrompt: function(e) {
            this.beforeInstallEvent = e;
            e.preventDefault();
        },
        createManifest: function() {
            let preUrl = window.location.origin + process.env.VUE_APP_PUBLIC_PATH;
            //if (this.board.uuid === process.env.VUE_APP_SHOW_IN_APP_UUID) {  // TESTING
                if (this.isIPhone) {                                        
                    history.replaceState({}, null, '/game?uuid=' + this.board.uuid);  // silently change URL to prevent iOS to use incorrect PWA start_url
                }
            //}
            const manifestURL = process.env.VUE_APP_API + '/manifest?uuid=' + this.board.uuid + '&url=' + preUrl;            
            document.getElementById('manifest').setAttribute('href', manifestURL);
        },
        initServiceWorker: function() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('sw.js').then(reg => {
                    console.log('SW registration successful, scope is:', reg.scope);
                }).catch((error) => {
                    console.log('Service worker registration failed, error:', error);
                });
            }
        },
        helpClick: function(e) {
            setTimeout(() => {
                this.helpExpanded = e.target.parentElement.getAttribute('open') !== null;
            }, 50);
        }
    }
}
</script>

<style scoped>
    ul, ol {
        list-style: revert;
    }
    li {
        @apply ml-5;
    }
</style>