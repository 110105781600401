<template>
    <div class="base-timer">
        <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" ref="timerCircle">
            <g class="base-timer__circle">
                <circle class="base-timer__path-elapsed" cx="50" cy="50" :r="radius" fill="white" />
                <path
                    id="base-timer-path-remaining" :stroke-dasharray="dashArray"
                    class="base-timer__path-remaining" :style="{'color': circleColor}" 
                    d="
                        M 50, 50
                        m -45, 0
                        a 45,45 0 1,0 90,0
                        a 45,45 0 1,0 -90,0
                    "
                ></path>
            </g>
        </svg>
        <div v-if="showColorWheel" class="absolute top-0">
            <ColorWheel :width="60" :height="60" :colors="colors" ref="colorWheel" />
        </div>
        <span id="base-timer-label" class="base-timer__label" :class="{'text-white': showColorWheel}" ref="timerValue">
            {{ timeLeft+1 }}
        </span>
    </div>
  
</template>

<script>
import ColorWheel from '@/components/ColorWheel.vue';

export default {
    props: {
        start: Number,
        colors: Array,
        showColorWheel: Boolean,
        sound: Object,
    },
    components: {
        ColorWheel
    },
    emits: [
        'timer-end'
    ],
    data() {
        return {
            radius: 45,
            startSec: 0,            
            timeLeft: 0,
            timePassed: 0,
            timerInterval: null,
        }
    },
    created() {        
        this.startTimer(this.start);        
    },
    beforeUnmount() {
        this.stopTimer();
    },
    computed: {
        dashArray() {
            const fullDash = Math.round(2*Math.PI*this.radius);
            const d = this.timeLeft / this.startSec * fullDash;
            return d.toFixed(0) + ' ' + fullDash;
        },
        circleColor() {
            if (this.timeLeft < 5) return '#D90000';
            else if (this.timeLeft < 10) return '#FF6600';
            else return '#41B883';
        }
    },
    methods: {
        startTimer: function(sec) {                        
            sec--;
            this.startSec = sec;      
            this.timeLeft = sec;
            this.timePassed = 0;      
            this.timerInterval = setInterval(() => {              
                this.timePassed++;
                this.timeLeft = sec - this.timePassed;
                if (this.timeLeft < 0) {                                    
                    this.sound?.play('beep');
                    clearInterval(this.timerInterval);                    
                    setTimeout(() => {
                        this.$emit('timer-end');   
                        this.$refs.timerValue.classList.remove('animate-timer');
                    }, 1000);       
                }                                
                else if (this.timeLeft < 3) {
                    this.$refs.timerValue.classList.add('animate-timer');                    
                    this.$refs.timerValue.classList.add('font-extrabold');   
                    this.sound?.play('blip');
                }                
            }, 1000);            
        },
        stopTimer: function() {
            if (this.timerInterval) {
                clearInterval(this.timerInterval);
            }
        },
    }
}
</script>

<style scoped>
    /* Sets the containers height and width */
    .base-timer {
        position: relative;
        height: 60px;
        width: 60px;
    }

    /* Removes SVG styling that would hide the time label */
    .base-timer__circle {
        fill: none;
        stroke: none;
    }

    /* The SVG path that displays the timer's progress */
    .base-timer__path-elapsed {
        stroke-width: 10px;
        stroke: grey;
    }
    .base-timer__label {
        position: absolute;
        
        /* Size should match the parent container */
        width: 60px;
        height: 60px;
        
        /* Keep the label aligned to the top */
        top: 0;
        
        /* Create a flexible box that centers content vertically and horizontally */
        display: flex;
        align-items: center;
        justify-content: center;

        /* Sort of an arbitrary number; adjust to your liking */
        font-size: 26px;
        font-weight: 600;
    }    
    .base-timer__path-remaining {
        /* Just as thick as the original ring */
        stroke-width: 10px;

        /* Rounds the line endings to create a seamless circle */
        stroke-linecap: round;

        /* Makes sure the animation starts at the top of the circle */
        transform: rotate(90deg);
        transform-origin: center;

        /* One second aligns with the speed of the countdown timer */
        transition: 1s linear all;

        /* Allows the ring to change color when the color value updates */
        stroke: currentColor;
    }

    .base-timer__svg {
        /* Flips the svg and makes the animation to move left-to-right */
        transform: scaleX(-1);
    }    
</style>