<template>
    <div>
        <div class="text-center">
            <div v-if="isVideo(currentPicture)" class="flex justify-center overflow-hidden">                
                <video style="margin: -100px 0" :width="currentPicture.width" :height="currentPicture.height" controls :autoplay=currentPicture.autoplay :muted=currentPicture.muted :loop=currentPicture.loop>
                    <source :src="currentPicture.video" type="video/mp4">
                    Nettleseren støtter ikke avspilling av video
                </video>                
            </div>
            <div v-else>
                <img :src="currentPicture.img" :alt="currentPicture.alt + ' stort'" class="inline-block" :class="{'border-2 shadow-lg p-2 border-gray-400 rounded-xl': currentPicture.border}">
            </div>
        </div>
        <div class="w-full flex flex-nowrap overflow-x-auto overflow-y-hidden mt-4" id="thumnail-scroll"> 
            <div v-for="(picture,i) in pictures" :key="i" class="mr-4 mt-3 flex-grow-0 flex-shrink-0 cursor-pointer items-center border border-transparent" :class="{'border-gray-400': picture === currentPicture}" @click="showPicture(picture, 'thumb'+i)">
                <img :src="picture.img" :alt="picture.alt" class="h-20 sm:h-24 p-1 rounded-lg" :id="'thumb' + i">
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    props: {
        pictures: Array,
    },
    data() {
        return {            
            currentPicture: null,
        }
    },
    created() {        
        this.showPicture(this.pictures[0]);      
    },    
    methods: {
        isVideo: function(picture) {
            return picture && picture.video;
        },
        showPicture: function(picture, thumbId) {
            this.currentPicture = picture;     
            if (thumbId) {       
                document.getElementById(thumbId).scrollIntoView({behavior: 'smooth', block: 'nearest',  inline: 'center'});
            }
        },        
    }
}
</script>
