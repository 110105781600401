import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './css/tailwind.css';
import FontAwesomeIcon from "@/fontawesome-icons.js";


const app = createApp(App).component("fa-icon", FontAwesomeIcon);

app.use(store);
app.use(router);

app.config.globalProperties.ainou = {
    name: 'Ainou',
    testMode: false,
    board: {
        name: 'Ainou',
        categoryCount: 10,
        questionCount: 5000,
    },
    travel: {        
        name: 'Ainou Mini',
        categoryCount: 6,
        questionCount: 1500,        
    },
};

app.mount('#app');