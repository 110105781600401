<template>
    <div>

        <div class="p-0 sm:p-2 w-full bg-blue text-white flex justify-between">            
            <div class="flex">
                <img src="/images/icons/58.png" alt="logo">
                <span class="mt-3">
                    <router-link to="/admin" class="text-2xl font-semibold tracking-wide">AINOU</router-link>    
                </span>
            </div>
            <div class="mr-1 text-sm mt-2 text-right" v-if="isUserLoggedIn">
                {{ user.name }}
                <span class="btn ml-2 cursor-pointer" @click="logout()">Log out</span>
                <div class="mt-1">
                    Free: {{ javaMemory.totalFreeMemory }} Mb / used: {{ javaMemory.usedMemory }} Mb
                </div>
            </div>
        </div>
        
        <div v-if="isUserLoggedIn">

            <div class="menu bg-blue">                           
                <div v-if="isContributor">
                    <button class="menu-btn" @click="openMenu(0)" @mouseover="openMenu(0)">Questions ▼</button>
                    <div class="menu-content hidden bg-blue" :ref="menus[0]" @mouseleave="closeMenu(0)">
                        <div @click="addQuestion()">Add +</div>
                        <div @click="routeTo('/admin/questions/list')">List</div>
                        <div v-if="isAdmin" @click="routeTo('/admin/questions/stats')">Stats</div>                    
                        <div v-if="isAdmin" @click="routeTo('/admin/questions/approve')">Approve</div>            
                        <div v-if="isAdmin" @click="routeTo('/admin/questions/expire')">Expire</div>
                        <div @click="routeTo('/admin/questions/scramble')">Word Scramble</div>
                        <div v-if="isAdmin" @click="routeTo('/admin/categories')">Categories</div>
                    </div>
                </div>    
                <div v-if="isBackoffice">
                    <button class="menu-btn" @click="openMenu(1)" @mouseover="openMenu(1)">Orders ▼</button>
                    <div class="menu-content hidden bg-blue" :ref="menus[1]"  @mouseleave="closeMenu(1)">                        
                        <div v-if="isBackoffice" @click="routeTo('/admin/orders/board')">Games</div>
                        <div v-if="isBackoffice" @click="routeTo('/admin/orders/category')">Categories</div>
                        <div v-if="isAdmin" @click="routeTo('/admin/sales')">Sales</div>    
                        <div v-if="isAdmin" @click="routeTo('/admin/discountCodes')">Discount Codes</div>
                        <div v-if="isAdmin" @click="routeTo('/admin/map')">Map</div>
                    </div>
                </div>                                                              
                <div class="menu-item" v-if="isAdmin"><router-link to="/admin/games">Games</router-link></div>
                <div class="menu-item" v-if="isAdmin"><router-link to="/admin/boards">Boards</router-link></div>                
                <div class="menu-item" v-if="isContributor"><router-link to="/admin/media">Media</router-link></div>                
                <div class="menu-item" v-if="isAdmin"><router-link to="/admin/cards">Cards</router-link></div>                
                <div class="menu-item" v-if="isAdmin"><router-link to="/admin/messages">Messages</router-link></div>           
                <div class="menu-item" v-if="isAdmin"><router-link to="/admin/test">Test</router-link></div>  
            </div>

            <div class="px-2">
                <router-view :key="$route.fullPath" :isAdmin="isAdmin"></router-view>                
            </div>
        </div>

        <div v-else class="mt-8 mx-auto w-48">
            <form @submit.prevent="login()">
                <div class="flex flex-col mb-2">
                    <label>Username</label>
                    <input type="text" v-model="loginForm.username" class="max-w-sm" required>
                </div>
                <div class="flex flex-col mb-2">
                    <label>Password</label>
                    <input type="password" v-model="loginForm.password" class="max-w-sm" required>
                </div>
                <button class="btn" type="submit">Log in</button>
            </form>
        </div>

        <EditQuestion v-if="isUserLoggedIn" ref="addQuestionDlg" :add="true" />
    </div>
</template>

<script>
import axios from 'axios';
import EditQuestion from '@/components/EditQuestion.vue';

export default {
    data() {
        return {
            loginForm: {
                username: '',
                password: ''
            },
            javaMemory: {},
            menus: [
                'questionsMenu',
                'ordersMenu',
            ],
        }
    },
    components: {
        EditQuestion,                
    },    
    computed: {
        isUserLoggedIn() {
            return this.$store.getters.author != null;
        },
        user() {
            return this.$store.getters.author;
        },
        isAdmin() {
            return this.user.role === 'admin';
        },
        isContributor() {
            return this.isAdmin || this.user.role === 'contributor';
        },
        isBackoffice() {
            return this.isAdmin || this.user.role === 'backoffice';
        }               
    },
    created() {
        this.getJavaMemory();
        document.dispatchEvent(new Event('render-trigger'));
    },
    methods: {
        login: function() {
            axios.post('/login', this.loginForm).then((res) => {   
                res.data.username = this.username;
                this.$store.commit('setAuthor', res.data);                
            }).catch((err) => {
                console.log(err);
                alert('Login failed. Try again..');
            });
        },
        logout: function() {
            this.$store.commit('logoutAuthor');
            this.$router.push({ name: 'admin'});
        },
        getJavaMemory: function() {
            axios.get('/java/memory').then(res => {
                this.javaMemory = res.data;
            });
        },
        openMenu: function(idx) {
            const menuRef = this.menus[idx];
            const menu = this.$refs[menuRef];               
            menu.classList.remove('hidden');            
            this.menus.forEach((m, i) => {            
                if (i !== idx) {
                    this.closeMenu(i);
                }
            });
        },
        closeMenu: function(idx) {
            const menuRef = this.menus[idx];
            const menu = this.$refs[menuRef];
            menu.classList.add('hidden');
        },
        addQuestion: function() {            
            this.closeMenu(0);
            this.$refs.addQuestionDlg.open();
        },
        routeTo: function(route) {
            this.$router.push(route);
        }
    }
}
</script>

<style scoped>
    .menu {
        @apply flex overflow-x-auto border-white border-t-2 mb-2;
    }
    .menu-btn, .menu-item {
        @apply text-lg text-white border-r-2 border-white px-2 py-1 whitespace-nowrap;
    }
    .menu-content {
        @apply text-white font-semibold py-1 shadow-md absolute z-10;
    }
    .menu-content div {
        @apply py-1 px-3 whitespace-nowrap w-full cursor-pointer;
    }
    .menu-content div a {
        @apply inline-block w-full;
    }
    .menu-content div:hover {
        @apply bg-blue-900;
    }
</style>