<template>
    <div class="w-full mx-auto border-2 rounded relative z-0" style="height: 80vh; width: 95vw;">
        <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :center="[64, 8]"   
        >
            <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>                    

            <l-marker-cluster-group>
                <l-marker v-for="(marker, m) in markers" :key="m" :lat-lng="marker">
                </l-marker>
            </l-marker-cluster-group>
        </l-map>                
    </div>

</template>

<script> 
import axios from 'axios';
import L from 'leaflet'; 

import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet';
import { LMarkerClusterGroup } from 'vue-leaflet-markercluster';    // https://github.com/veitbjarsch/vue-leaflet-markercluster

import 'leaflet/dist/leaflet.css';
import 'vue-leaflet-markercluster/dist/style.css';


export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,    
        LMarkerClusterGroup,          
    },    
    data() {
        return {
            zoom: 5, 
            markers: [],
            zipCodes: new Map(),
        }
    },
    created() {
        this.getZipCodes();        
    },
    methods: {
        L,
        getZipCodes: function() {            
            axios.get(location.origin + '/data/postnummer.csv').then(res => {
                const rows =  res.data.split('\r\n');
                rows.forEach(row => {
                    const zipRow = row.split(',');
                    if (zipRow.length == 3) {
                        let zip = zipRow[0];
                        while (zip.length < 4) {
                            zip = '0' + zip;
                        }
                        const lat = Number(zipRow[1].trim());
                        const lng = Number(zipRow[2].trim());  
                        this.zipCodes.set(zip, [lat, lng]);
                    }
                });
                axios.get('/orders/board').then(res => {                              
                    this.getOrderZip(res.data.processed_orders);
                });
            });
        },
        getOrderZip: function(orders) {
            //console.log('orders: ' + orders.length);  
            orders.forEach(order => {
                const latLng = this.zipCodes.get(order.zip);
                if (latLng) {
                    this.markers.push(latLng);                
                }
                else {
                    console.log('not found: ', order.zip);
                }
            });
        },             
    }
}
</script>