<template>
    <svg :height="fullDiam" :width="fullDiam" class="inline mr-2">
        <circle :cx="radius+outline/2" :cy="radius+outline/2" :r="radius" stroke="gray" :stroke-width="outline" :fill="fill" />
    </svg>
</template>

<script>
export default {
    
    props: {
        diameter: Number,
        outline: Number,
        fill: String,
    },
    computed: {
        fullDiam() {
            return this.diameter + (2*this.outline);
        },
        radius() {
            return this.diameter/2;
        }
    }
}
</script>