<template>
    <div class="font-semibold mb-2 text-lg">{{name}}</div>

    <table>
        <tr>
            <th>Code</th>
            <th>Discount</th>
            <th>Free shipping</th>
            <th>Expire</th>
            <th>Used</th>                
        </tr>
        <tr v-for="(dc, i) in discountCodes" :key="i">
            <td>{{dc.code}}</td>
            <td class="text-right">{{dc.discount}}</td>
            <td class="text-center"><span v-if="dc.freeShipping"><fa-icon icon="check"/></span></td>
            <td class="whitespace-nowrap">{{formatDate(dc.expire)}}</td>
            <td class="text-right">{{dc.count}}/{{dc.maxCount}}</td>
        </tr>
    </table>      

</template>

<script>
import {formatDate} from '@/utils.js';

export default {
    props: {
        name: String,
        discountCodes: Array,
    },
    methods: {
        formatDate
    }
}
</script>

<style scoped>

table td, table, th {
    @apply px-2;
}
</style>