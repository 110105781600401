<template>
    <div>
        <div class="pt-1 mb-3 overflow-x-auto whitespace-nowrap">
            <form v-if="addVisible" v-on:submit.prevent>
                <button class="btn btn-disable" @click="randomize()">Random</button>
                <input type="text" v-model="add.code" placeholder="code" size="6" required>
                <input type="text" v-model="add.discount" placeholder="discount" size="4" required>
                <input type="checkbox" v-model="add.freeShipping" id="freeShipping"><label for="freeShipping">Free shipping</label>
                <input type="date" v-model="add.expire" placeholder="expire" required>
                <input type="number" v-model="add.maxCount" required>
                <button class="btn" @click="addCode()">Add</button>
            </form>
            <div v-else>
                <button class="btn" @click="addVisible=true">Add discount code</button>
            </div>
        </div>

        <div v-if="discountCodes" class="overflow-x-auto">
            <DiscountCodeTable name="Active codes" :discountCodes="discountCodes.active" />
            <br>
            <DiscountCodeTable name="Used/expired" :discountCodes="discountCodes.used" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import DiscountCodeTable from '@/components/DiscountCodeTable.vue';


export default {
    components: {
        DiscountCodeTable
    },
    mounted() {
        this.getCodes();
    },
    data() {
        return {
            randomCodeLength: 6,
            randomCharacters: 'abcdefghjkmnpqrstuvwxyz', 
            discountCodes: null,
            addVisible: false,
            add: {
                code: '',
                discount: '',
                freeShipping: false,
                expire: '',
                maxCount: 1,
            }
        }
    },
    methods: {        
        getCodes: function() {
            axios.get('/discountCodes').then((res) => {
                this.discountCodes = res.data;
            });
        },
        addCode: function() {
            if (!this.add.code || !this.add.discount || !this.add.expire || this.add.maxCount <= 0) {
                return;
            }
            axios.post('/discountCode', this.add).then((ret) => {
                if (ret.data.success) {
                    this.add.code = '',
                    this.add.discount = '',
                    this.add.expire = '',
                    this.add.freeShipping = false,
                    this.add.maxCount = 1;
                    this.addVisible = false;
                    this.getCodes();
                }
                else {
                    console.log('exists');
                }
            });
        },
        randomize: function() {
            let rand = '';
            for (let i = 0; i < this.randomCodeLength; i++) {
                let r = Math.floor(Math.random() * this.randomCharacters.length);
                rand += this.randomCharacters[r];
            }
            this.add.code = rand;
        },
    }
}
</script>

<style scoped>
    input, label {
        margin-left: 2px;
        margin-right: 2px;
    }

    input[type='number'] {
        width: 80px;
    } 

</style>
