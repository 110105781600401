<template>
    <div class="relative">
        <svg :width="width" :height="height" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <circle v-if="travel" cx="200" cy="200" r="160" fill="#07304E" stroke="#FFC200" stroke-width="4" />
            <defs v-else>
                <path id="piePiece" d="M 200 200 200,40 a160 160, 0, 0, 1, 139,80 Z" />  
            </defs>
            <use href="#piePiece" :fill="segmentColor(0)" />
            <use href="#piePiece" transform="rotate(60,200,200)" :fill="segmentColor(1)" />
            <use href="#piePiece" transform="rotate(120,200,200)" :fill="segmentColor(2)" />
            <use href="#piePiece" transform="rotate(180,200,200)" :fill="segmentColor(3)" />
            <use href="#piePiece" transform="rotate(240,200,200)" :fill="segmentColor(4)" />
            <use href="#piePiece" transform="rotate(300,200,200)" :fill="segmentColor(5)" />
        </svg>     
        <div v-if="travel" class="absolute" style="top:50%; transform: translate(0%, -50%)">
            <img src="/images/travel_common_symbol.png" style="transform: scale(0.6)">
        </div>                  
        <div v-else-if="symbol" class="absolute w-full text-4xl pb-0.5 pl-0.5" style="top:50%; transform: translate(0%, -50%)">{{ symbol }}</div>
    </div>
</template>

<script>
export default {
    props: {
        width: Number,
        height: Number,
        colors: Array,
        travel: Boolean,
        symbol: String,  // not applicable for travel mode
    },
    methods: {
        segmentColor: function(idx) {
            return this.colors[idx].bg;
        }
    }
}
</script>