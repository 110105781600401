<template>
    <div> 
        Media
    </div>
</template>

<script>
import axios from 'axios';

export default {

    data() {
        return {

        }
    },
    created() {
        this.getMedia();
    },
    methods: {
        getMedia: function() {
            axios.get('/media').then((res) => {
                console.log(res.data);
            });
        }
    }
}
</script>