<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">
            <div class="modal-content text-center bg-blue text-white min-h-full">                
                <div class="flex justify-end -mr-2 -mt-2 mb-0" @click="close()">
                    <svg class="h-8 w-8" viewBox="0 0 24 24" stroke="white" fill="white">
                        <path v-if="showClose" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>                    
                </div>  
                <div v-if="showScore">              
                    <div class="modal-header text-white text-3xl">Din score</div>
                    <div class="text-center">
                        <div class="points">{{ scoreData.normalPoints }}</div>
                        <div id="commonPoints" class="reveal mt-4">
                            <details>
                                <summary class="text-lg">Fellesrunder <div class="text-xs -mt-0.5 ml-4">(trykk for resultat)</div></summary>
                                <div class="mt-2">
                                    <div class="text-sm font-semibold flex justify-between">                                    
                                        <div class="ml-10">Spørsmål</div>                                        
                                        <div>Ditt svar | Fasit</div>
                                    </div>                            
                                    <div class="mb-2 border-b border-t">
                                        <div v-for="(result, r) in scoreData.commonResults" :key="r" class="flex gap-x-2 items-start w-full my-1.5 pr-1"> 
                                            <div style="min-width: 2rem">
                                                <img v-if="result.point" src="/images/travelPoints.svg" class="mx-auto" style="width: 1.1rem; margin-top: 2px;" />
                                                <span v-else>❌</span>
                                            </div>
                                            <div class="text-sm text-left flex-grow">{{ result.question }}</div>
                                            <div class="text-sm text-right whitespace-nowrap">{{ result.playerAnswer }} | {{ result.correctAnswer }}</div>
                                        </div>
                                    </div>
                                </div>
                            </details> 
                            <div class="points">{{ scoreData.commonPoints }}</div>                                                               
                            <div class="text-3xl">=</div>
                            <div class="points">{{ scoreData.normalPoints + scoreData.commonPoints }}</div>
                        </div>
                        <div id="placement" class="reveal">
                            <div class="text-2xl mt-8">
                                <div class="text-6xl mb-2">{{ prize }}</div>
                                {{ scoreData.place }}. plass
                            </div>
                            <div v-if="totalPoints > 0" class="text-3xl mt-8">{{ greeting }}</div>
                        </div>
                    </div> 
                </div>
                <div v-else class="flex flex-col gap-y-8">                    
                    <div class="modal-header text-white text-3xl">Spillet er slutt</div>                    
                    <div class="text-6xl">🏁</div>                    
                </div>                
            </div>
        </div>
    </teleport>
</template>

<script>

export default {
    props: {
        sound: Object,
    },
    computed: {
        totalPoints() {
            return this.scoreData.normalPoints + this.scoreData.commonPoints;
        },
        greeting() {
            let text = '';
            switch (this.scoreData.place) {
                case 1: text = 'Gratulerer 🎉'; break;
                case 2: text = 'Godt jobbet 🎊'; break;
                case 3: text = 'Du kom på pallen!'; break;
                default: text = 'Bedre lykke neste gang';
            }
            return text;
        },
        prize() {
            let prize = '';
            switch (this.scoreData.place) {
                case 1: prize = '🥇'; break;
                case 2: prize = '🥈'; break;
                case 3: prize = '🥉'; break;
                default: prize = '💐';
            }
            return prize;            
        }
    },
    data() {
        return {
            show: false,
            showClose: false,
            showScore: false,
            scoreData: {},
            revealDelay: 2000,  // ms            
        }
    },
    methods: {
        open: function(scoreData) {
            console.log(scoreData);
            this.scoreData = scoreData;
            this.showScore = false;
            this.show = true;
            this.showClose = false;

            /*this.$nextTick(() => {
                document.getElementById('omh').classList.add('show');            
            });*/
            
            this.startAnimation();
        },
        close: function() {
            this.show = false;
        },
        startAnimation: function() {
            setTimeout(() => {
                this.showScore = true;
                setTimeout(() => {
                    document.getElementById('commonPoints').classList.add('show');
                    setTimeout(() => {
                        document.getElementById('placement').classList.add('show');  
                        this.playSound();
                        this.showClose = true;         
                    }, this.revealDelay);                                
                }, this.revealDelay);
            }, this.revealDelay);
        },
        playSound: function() {
            if (this.scoreData.place === 1) {
                this.sound?.play('winner'); 
            }
        },
    },
}

</script>

<style scoped>

.reveal {
    @apply opacity-0;
}
.show {
    @apply duration-1000 transition ease-in opacity-100;
}

.points {
    @apply font-bold text-6xl mb-2;
}

details > summary {
    list-style-type: '+ ';
    letter-spacing: 1px;
}
</style>