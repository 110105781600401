<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal">
            <div class="modal-content max-w-sm text-lg">

                <div class="flex justify-between">
                    <div>
                        <div v-if="travel">
                            <div class="pb-8">
                                <button class="btn bg-blue-800" @click="toggleRules()">📖 Spilleregler</button>
                            </div>
                        </div>
                    </div>
                    <div class="-mr-2 -mt-2 mb-4" @click="close()">
                        <svg class="h-10 w-10" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                        </svg>                    
                    </div>
                </div>

                <div v-if="showTravelRules">
                    <MiniInstructions />
                </div>                

                <div v-else class="text-center flex flex-col mx-6">

                    <div class="text-white font-semibold py-1 mb-1 tracking-wider rounded" :class="isOrganizer ? 'bg-green-500' : 'bg-yellow-500'">
                        {{ isOrganizer ? 'Organisator' : 'Spiller' }} 
                    </div>

                    <button v-if="!isOrganizer" @click="sendEvent('new-organizer', false)" class="btn btn-menu">Bli organisator</button>
                            
                    <div class="border-b border-gray-400 mb-4">
                        <div class="menuItem mt-4">
                            Antall spillere: {{ playerCount }}                        
                        </div>

                        <div v-if="gameStarted" class="menuItem">
                            Tidsgrense:                        
                            <button v-if="isOrganizer" class="btn ml-2 text-base" @click="sendEvent('set-question-time', true)">{{ questionTime }}</button>
                            <span v-else>{{ questionTime }}</span>
                        </div>                    
                        
                        <div class="menuItem">
                            Din alder: 
                            <button class="btn ml-2 text-base" @click="sendEvent('set-age', true)">{{ ageAlias }}</button>
                        </div>
                        
                        <div class="menuItem">
                            Spill: 
                            <span v-if="gameStarted" class="font-mono">{{ boardCode }}</span>
                            <button v-else class="btn ml-2 text-base font-mono" @click="scanQR()">{{ boardCode }}</button>                       
                        </div>     
                        <div v-if="travel && isOrganizer">
                            <div v-if="connectedCode" class="menuItem"> 
                                <div class="text-sm mb-1">Koblet brettspill</div>
                                <span class="font-mono">{{ connectedCode }}</span><button :disabled="gameStarted" class="btn ml-2 text-base" @click="disconnectAinou()">Koble fra</button>
                            </div>
                            <div v-else-if="!gameStarted" class="menuItem">
                                <div class="text-sm mb-2">Dersom du også eier brettspill-versjonen av Ainou kan du koble kategoriene derfra og bruke de her</div>
                                <div class="flex justify-center gap-x-2">
                                    <input type="text" class="w-20" placeholder="Kode*" v-model="ainouCode"><button class="btn text-base" @click="connectAinou()">Koble</button>                                
                                </div>
                                <div class="mt-1 text-xs text-gray-600">* Brettspillkoden som består av 5 bokstaver/tall finner du i kvitterings-eposten eller i menyen når du spiller brettspillet</div>
                                <div v-if="connectErr" class="text-base mt-2 bg-red-200 border rounded px-3 py-1">{{ connectErr }}</div>
                            </div>
                        </div>    
                    </div>                  

                    <button v-if="isOrganizer && gameStarted" @click="endGame()" class="btn btn-menu">Avslutt runde</button>

                </div>    

                <div class="text-center text-sm text-gray-500 mt-10 -mx-6 -mb-4">                    
                    <Copyright />    
                    Bilder brukt under lisens fra Shutterstock.com
                    <BuildVersion class="mt-1 font-semibold" />
                </div>        

            </div>
        </div>

        <Alert ref="confirm" :textLarge="true" :confirmMode="true" />
        <ScanQR ref="scanQR" :closable="true" />

    </teleport>
</template>

<script>
import axios from 'axios';
import Alert from '@/components/Alert.vue';
import ScanQR from '@/components/ScanQR.vue';
import Copyright from '@/components/Copyright.vue';
import BuildVersion from '@/components/BuildVersion.vue';
import MiniInstructions from '@/components/MiniInstructions.vue';

export default {
    props: {
        playerCount: Number,
        isOrganizer: Boolean,
        gameStarted: Boolean,
        ageAlias: String,
        questionTime: String,
        boardCode: String,
        connectedCode: String,
        travel: Boolean,
    },
    components: {
        Alert,
        ScanQR,
        Copyright,
        BuildVersion,
        MiniInstructions,
    },
    data() {
        return {
            show: false,
            showTravelRules: false,
            ainouCode: '',
            connectErr: '',
        }
    },
    emits: [
        'new-organizer', 'end-game', 'set-age', 'new-board', 'set-question-time', 'ainou-connected', 'ainou-disconnected'
    ],
    methods: {
        open: function() {
            this.show = true;
        },
        close: function() {            
            this.show = false;
            this.showTravelRules = false;
        },
        sendEvent: function(eventName, closeMenu, data) {        
            this.$emit(eventName, data);     
            this.show = !closeMenu;       
        },
        endGame: function() {
            const endGameTxt = this.travel ? 'Spillet avsluttes automatisk når tiden er ute. Vil du allikevel avslutte nå?' : '';
            this.$refs.confirm.open('Avslutt runde?', endGameTxt, 'Ja', 'Nei').then((ok) => {                
                if (ok) {
                    this.sendEvent('end-game');
                    this.close();                
                }
            });
        },
        scanQR: function() {
            this.$refs.scanQR.getUUID(null, false, true).then((board) => {                      
                if (board) {  
                    this.sendEvent('new-board', false, board);
                }
            });
        },
        toggleRules: function() {
            this.showTravelRules = !this.showTravelRules;
        },
        connectAinou: function() {
            this.connectErr = '';
            const data = {
                travelCode: this.boardCode,
                ainouCode: this.ainouCode,
            };
            axios.post('/travel/connect', data).then(() => {                
                this.sendEvent('ainou-connected', false, this.ainouCode);
            }).catch(err => {              
                switch (err.response.status) {
                    case 404: this.connectErr = 'Fant ingen spill med denne koden'; break;                    
                    case 409: this.connectErr = 'Dette spillet er allerede koblet'; break;  
                }
            });
        },
        disconnectAinou: function() {      
            this.$refs.confirm.open('Koble fra?', 'Er du sikker på at du vil koble fra spillet?', 'Ja', 'Nei').then((ok) => {    
                if (ok) {
                    const data = {
                        travelCode: this.boardCode,
                    };                  
                    axios.post('/travel/disconnect', data).then(() => {
                        this.sendEvent('ainou-disconnected', false);
                        this.ainouCode = '';
                    });
                }
            });
        },
    }
}
</script>

<style scoped>

.menuItem {
    @apply border-t border-gray-400 py-2;
}

.btn:disabled {
    @apply bg-gray-400;
}
</style>