<template>
    <div class="bg-gray-200 sm:pt-4">
        <div class="bg-blue p-4 sm:px-10 sm:py-6 md:mx-20 lg:mx-40 2xl:mx-64 h-full text-white text-lg">

            <div class="sm:flex items-center gap-x-5 xl:gap-x-12 text-3xl sm:text-4xl xl:text-5xl">
                <div>
                    <img src="/images/ainou-email.png" alt="Ainou">
                </div>
                <div class="flex justify-start items-center gap-x-2">
                    <h1 class="text-white font-semibold">Julegave for bedrifter</h1>
                    <div>🎄</div>
                </div>
            </div>

            <div class="sm:mt-8">
                Trenger du julegave til dine ansatte? 
                Brettspillet Ainou er et interaktivt kunnskapsspill alle vil få glede av. 
                Spillet passer for ansatte med og uten barn, da spørsmålene tilpasses alder automatisk!
                Les mer om spillet <router-link to="/" class="underline cursor-pointer">her</router-link>.
            </div>

            <div class="mt-6 sm:flex justify-between sm:gap-x-5">                
                <div class="w-full md:w-1/2">                                
                    <div>
                        ✨ Vi har nå et spesial-tilbud for bedrifter:
                    </div>
                    <div class="priceTable px-3 pb-2 rounded border my-3 inline-block">
                        <table class="w-full">
                            <thead>
                                <tr><th>Antall spill</th><th>Pris/stk.</th></tr>
                            </thead>
                            <tbody>
                                <tr><td>3-10</td><td>600,-</td></tr>                            
                                <tr><td>11-50</td><td>550,-</td></tr>
                                <tr><td>> 50</td><td>500,-</td></tr>                        
                            </tbody>
                        </table>
                    </div>
                    <div>
                        🎁<br>Spillene leveres ferdig innpakket i gavepapir om ønskelig.                        
                    </div>
                    <div class="mt-3">
                        🚚<br>Vi leverer spillene til deg gratis! <div class="text-sm">(gjelder Østlandsområdet)</div>
                    </div>
                </div>
                <div class="w-full md:w-1/2 mt-4 sm:mt-0">
                    <ImageCarousel :pictures="pictures" />
                </div>
            </div>

            <div class="mt-8">
                <div class="font-semibold mb-2 sm:mb-4 text-xl">
                    Ved interesse send oss din e-post for nærmere avtale                    
                </div>
                <form @submit="submitContact" @submit.prevent class="w-full xl:w-1/2">
                    <div>
                        <input type="text" placeholder="Navn" autocomplete="name" v-model="contactForm.name">
                    </div>
                    <div>
                        <input type="email" placeholder="E-post" autocomplete="email" v-model="contactForm.email" required>
                    </div>
                    <div>
                        <textarea class="h-24 resize-none" placeholder="Kort info om antall eller spørsmål du ønsker svar på" v-model="contactForm.message" required></textarea>
                    </div>
                
                    <div v-if="submitOk" class="bg-green-100 text-green-800 mb-4 p-2 border rounded border-gray-200">
                        Takk for din interesse!<br>Du får svar fra oss innen kort tid 😊
                    </div>

                    <button class="btn text-xl px-8">Send</button>
                </form>          
            </div>

        </div>

        <footer>
            <div class="text-center py-4">
                Happy Dice AS
            </div>
        </footer>
    </div>
</template>

<script>
import axios from 'axios';
import ImageCarousel from '@/components/ImageCarousel.vue';

export default {
    components: {
        ImageCarousel
    },
    data() {
        return {
            pictures: [
                { img: '/images/peiskos.jpg', alt: 'Video av spill', video: '/images/peiskos.mp4', autoplay: true, muted: true, loop: true, width: 300 },
                { img: '/images/product-1.png', border: true, alt: 'Spillebrett og eske' },
                { img: '/images/product-10.jpg', border: false, alt: 'Anmeldelse terningkast 5' },
                { img: '/images/product-game1.jpg', border: true, alt: 'Spillebrett nærbilde 1' },
                { img: '/images/product-game2.jpg', border: true, alt: 'Spillebrett nærbilde 2' },          
            ],
            contactForm: {
                name: '',
                email: '',
                message: '',
            },
            submitOk: false,
        }
    },
    created() {
        document.dispatchEvent(new Event('render-trigger'));  // pre-render
    },
    methods: {
        submitContact: function() {
            this.submitOk = false;
            axios.post('/julegave', this.contactForm).then(() => {            
                this.contactForm.name = '';
                this.contactForm.email = '';
                this.contactForm.message = '';
                this.submitOk = true;
            });
        }
    }
}
    
</script>

<style scoped>
    table th {
        @apply px-2;
    }
    table td {
        @apply font-semibold text-center;
    }
    input, textarea, .priceTable {
        background-color: #174271;
        border: 1px solid lightgray !important;
        margin-bottom: 0.5rem;
        width: 100%;
    }
</style>