<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import { Line } from 'vue3-chart-v2';

export default defineComponent({
    name: 'MonthlyChart',
    extends: Line,
    props: {
        week: String,
    },
    emits: [
        'count',
    ],
    data() {
        return {
            totCount: 0,
            chartData: {},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1,
                        }
                    }]
                },   
                /*elements: {        
                    line: {
                        tension: 0
                    }
                },*/
                locale: 'no-NO',
                legend: {
                    display: false,
                }
            },     
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        }
    },
    mounted() {                   
        this.getChartData();
    },
    methods: {
        getChartData: function() {
            axios.get('/chart/games?week=' + this.week).then(res => {                
                this.parseData(res.data);
                this.renderChart(this.chartData, this.chartOptions);
            });
        },
        parseData: function(data) {            
            //console.log(data);
            this.chartData.labels = [];
            this.chartData.datasets = [
                {
                    label: 'Games played',
                    data: [],
                    backgroundColor: 'rgba(51, 153, 102, 0.3)',
                    pointRadius: 0,
                }
            ];

            let rowCount = 0;
            this.totCount = 0;
            //let currentDay;
            for (let d = 0; d < 7; d++) {  // days (1 week)                                                               
                while (rowCount < data.length && data[rowCount].weekday < d) {
                    rowCount++;
                }                
                for (let h = 0; h < 24; h++) {  // hours each day
                    let count = 0;
                    const row = rowCount < data.length ? data[rowCount] : {};
                    //console.log(row);
                    if (row.weekday === d && row.hour === h) {                        
                        count = row.count;
                        if (rowCount+1 < data.length) {
                            rowCount++;
                        }
                    }
                    
                    const day = h === 0 ? this.days[d] : '';
                    let hour = h < 10 ? '0' + h : h;
                    hour = hour === '00' ? '' : hour + ':00';
                    this.chartData.labels.push(day + ' ' + hour); 
                    this.chartData.datasets[0].data.push(count);                    
                    this.totCount += count;                    
                }                
            }
            this.$emit('count', this.totCount);
        },
    }
});
</script>

<style scoped>
    canvas {
        width: 100% !important;
        height: 400px !important;
    }
</style>