<template>
    <div class="pt-10 text-center text-white bg-blue h-screen">
                
        <img src="/images/icons/120.png" alt="Ainou" class="inline-block">        
        <div class="text-4xl font-semibold mt-4">Oops!</div>
        <div class="text-2xl mt-6">Denne siden finnes ikke...</div>

    </div>
</template>

<script>
export default {
    
}
</script>