<template>

    <div    
        @mousedown="tapDown"
        @mouseup="tapUp"    
        @mouseout="tapCancel"
        @touchstart="tapDown"
        @touchend="tapUp" 
        @touchcancel="tapUp"
        @touchleave="tapCancel"
        @touchmove="tapSwipe"
                
        :class="{
            'bg-blue-light': !disabled && !selected,  // selectable
            'bg-green-900': pressed && !disabled, // pressed            
            'bg-green-700': selected,   // selected
            'text-gray-200': disabled   // disabled
        }" 
        class="h-14 rounded-full text-white text-center font-semibold tracking-wider px-2 relative border-2 border-gray-400 cursor-pointer"
    >
        <span class="h-full inline-flex items-center">{{ name }}</span>
        <span v-if="isNew" class="text-xs absolute rounded-full border bg-green-700 text-white px-4 -mt-2 left-0" >Nyhet!</span>
        <span v-if="hasPrice" class="text-sm absolute rounded-full border bg-red-500 text-white right-0 pl-2 pr-1 py-2 -m-4">{{ price }},-</span>
        <span v-if="minAgeLabel" class="text-xs absolute rounded-full border bg-blue text-white right-0 pl-2 pr-1 py-1 -m-3">{{ minAgeLabel }}</span>               
        <span v-if="icon" class="absolute -right-5 -mt-5 animate-wiggle">
            <img :src="'/images/' + icon" width="50" class="transform -scale-x-100">
        </span>
    </div>

</template>

<script>
export default {
    props: {
        name: String,
        selected: Boolean,
        disabled: Boolean,
        price: Number,
        isNew: Boolean,
        minAgeLabel: String,
        icon: String,
    },
    computed: {
        hasPrice() {
            return this.price !== undefined;
        },    
    },
    data() {
        return {
            clicked: false,
            pressed: false,
            swipe: false,
        }
    },
    emits: [
        'selected',
    ],
    methods: {        
        select: function() {
            if (!this.disabled) {
                this.$emit('selected');
            }
        },
        tapDown: function() {
            this.pressed = true;
            this.clicked = true;
            this.swipe = false;
        },
        tapUp: function(e) {
            e.preventDefault();  // to prevent both touch and click event
            if (this.clicked && !this.swipe) {
                this.select();
            }
            this.tapCancel();
        },
        tapCancel: function(e) {
            if (e) {
                e.preventDefault();
            }
            this.pressed = false;
            this.clicked = false;            
        },
        tapSwipe: function() {
            this.swipe = true;
            //this.pressed = false;
        },
    }
}
</script>