<template>
    <div>
        <Navbar v-on:showCart="showCart" />
        <div class="pt-20 sm:pt-32"></div> <!-- dummy for top margin -->
                
        <div v-if="travel" class="px-4 sm:px-6 lg:px-12 xl:px-24 text-center sm:text-left">
            <div class="sm:flex">
                <div class="w-full sm:w-1/2">
                    <ImageCarousel2 :pictures="pictures" />
                </div>

                <div class="w-full sm:w-1/2 pl-0 sm:pl-8">
                    <h1 class="text-2xl sm:text-4xl">
                        Et unikt spill-konsept!                     
                    </h1>
                    <div class="mt-1 text-xl"> 
                        Ainou Mini er et dynamisk kunnskapsspill for hele familien der spørsmålene tilpasses alder automatisk!
                        Enkelt å ha med overalt. Består av spinner med unik QR-kode på undersiden som scannes med mobilen for å spille.
                    </div>                
                    <div class="my-6 relative">
                        <!--div class="absolute right-0 sm:right-4 rotate-12 transform bg-blue text-white rounded sm:text-lg px-4 py-1">
                            Kun på nett!
                        </div-->                    
                        <div class="flex flex-col items-center sm:flex-row font-semibold">
                            <span class="sm:mr-10 mb-4 sm:mb-0 text-red-600 text-5xl text-center">{{ travel.price }},-</span>
                            <span v-if="travel.count > 0" class="buyBtn" @click="addTravelGameToCart()">Kjøp nå!</span>
                        </div>
                        <div class="mt-3 text-gray-700">
                            {{ getStockText(travel.count) }} stk. på lager
                        </div>
                    </div>
                    <div class="pt-2">
                        <div class="text-left text-lg relative bulletPoints">
                            <ul class="ml-4" v-for="(bp, i) in bulletPoints" :key="i">
                                <li>
                                    <span style="margin-left: -35px; margin-right: 8px;"><Circle :diameter="18" :outline="0" :fill="getBulletColor(i)" /></span><span v-html="bp"></span>
                                </li>
                            </ul>
                        </div>    
                    </div>
                </div>
            </div>

            <div class="mt-6 text-left">
                <h2>Spørsmålskategorier</h2>
                <div class="text-lg">
                    {{ ainou.travel.categoryCount }} kategorier med til sammen {{ ainou.travel.questionCount }} spørsmål er inkludert når du kjøper Ainou Mini. 
                    Flere kategorier kan kjøpes enkeltvis i appen. Her kan du lese mer om kategoriene som er tilgjengelig:
                </div>
                
                <div class="mt-4"> 
                    <div v-for="(cat,i) in sortedCategories" :key="i" class="mt-3">
                        <div v-if="cat.active && cat.price > 0" class="flex">
                            <div class="border-t pt-2 w-full">
                                <h3>
                                    {{cat.name}}
                                    <span v-if="cat.travel" class="ml-1 font-normal text-sm text-white bg-blue px-2 rounded">Inkludert</span>
                                </h3>
                                <div v-html="cat.desc" class="text-gray-700"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            

        </div> 
    </div>
    <Footer />

    <BuyGame ref="buyDlg" :testMode="ainou.testMode" :shipping="board.shipping" :shippingTravel="travel.shipping" :maxPayTime="maxPayTime" v-on:close="getProductInfo()" />

</template>

<script>
import axios from 'axios';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import ImageCarousel2 from '@/components/ImageCarousel2.vue';
import Circle from '@/components/Circle.vue';
import { getStockText } from '@/utils.js';
import BuyGame from '@/components/BuyGame.vue';


export default {
    components: {
        Navbar,
        Footer,
        ImageCarousel2,
        Circle,
        BuyGame,      
    },
    computed: {
        sortedCategories() {
            const travelCategories = this.categories.filter(c => c.travel);
            const otherCategories = this.categories.filter(c => !c.travel);
            return travelCategories.concat(otherCategories);
        }
    },
    data() {
        return {
            maxPayTime: null,
            board: {},
            travel: {},
            categories: [],  
            pictures: [                      
                { img: '/images/mini/spinner-box.png', border: false, alt: 'Spinner og eske' },                
                { img: '/images/mini/app-start.png', border: false, alt: 'App kategorier' },
                { img: '/images/mini/app-categories.png', border: false, alt: 'App kategorier' },
                { video: '/images/mini/spinner.mp4', autoplay: true, muted: true, loop: true, width: 400, height: 400, alt: 'Video av spinner' },
                { img: '/images/mini/app-question.png', border: false, alt: 'App spørsmål' },
                { img: '/images/mini/app-fellesrunde.png', border: false, alt: 'App fellesrunde' },
            ],   
            bulletColors: ['#EA332E','#F58230','#F4D051','#4A9C43','#1D5893','#A32488'],         
            bulletPoints: [
                'Interaktivt kunnskapsspill for hele familien',
                'Kombinasjon av fysisk spinner og app!',
                'Bestem selv hvor lenge spillet skal vare',
                'Poeng samles i appen og vinneren avsløres når spillet er slutt',                
                '6 kategorier medfølger med tilsammen 1500 spørsmål, i tillegg kan flere kategorier kjøpes i appen',                                
                'Dersom du har brettspill-utgaven kan kategoriene derfra kobles til mini-utgaven',
                'Barn og voksne kan spille sammen, spillet tilpasser automatisk spørsmålene etter alder',
                '3 aldersgrupper: Barn (10-13), ungdom (13-16) og voksen (16+). Hver spiller velger sin egen alder i appen',
                '1 - 10 spillere',
                'Støtter iPhone (iOS ver. 11.3+) og Android (ver. 4.4+, Chrome 53+). Trenger internettforbindelse',
            ],            
        }
    },
    created() {
        this.getProductInfo();
        this.getCategories();
    },
    methods: {
        getStockText,
        getProductInfo: function() {
            axios.get('/products').then(res => {
                this.board = res.data.board;
                this.travel = res.data.travel;
                this.maxPayTime = res.data.releaseTime;                
            });
        }, 
        getCategories: function() {
            axios.get('/question/categories').then(res => {
                this.categories = res.data; //.filter(c => c.travel === true);              
                document.dispatchEvent(new Event('render-trigger'));
            });        
        },         
        getBulletColor: function(idx) {
            while (idx >= this.bulletColors.length) {
                idx -= this.bulletColors.length;
            }
            return this.bulletColors[idx];
        },        
        addTravelGameToCart: function() {
            const now = new Date();
            const product = {
                name: this.ainou.travel.name,
                price: this.travel.price,
                travelGame: true,
                time: now.getTime(),
            };
            this.$store.commit('addToCart', product);   
            this.showCart();
        },    
        showCart: function() {
            this.$refs.buyDlg.open(false);
        },                      
    }
}

</script>