<template>
  <div class="bg-white">
    <router-view :key="$route.fullPath"></router-view>
    <LoadingScreen ref="loadingScreen" />
  </div>
</template>

<script>
import axios from 'axios';
import LoadingScreen from '@/components/loading-screen.vue';

export default {
  name: 'App',
  components: {
    LoadingScreen
  },
  beforeCreate() {
    this.$store.commit('initializeStore');
  },
  created() {
    axios.defaults.baseURL = process.env.VUE_APP_API;     
  },
  methods: {
      showLoading: function(b) {
          const ls = this.$refs.loadingScreen;            
          if (b !== undefined) {
              ls.show(b);
          }                            
      },    
  } 
}
</script>

