<template>
    <div>
        <div class="font-semibold mb-2">Search result</div>
        <div>
            <MediaList :media="searchResult" v-on:edit="reloadSearch()" />
        </div>
    </div>    
</template>

<script>
import axios from 'axios';
import MediaList from '@/components/MediaList.vue';

export default {
    components: {
        MediaList
    },    
    props: [
        "s" 
    ],
    data() {
        return {
            searchResult: [],
        }
    },
    mounted() {
        this.doSearch(this.s);
    },
    watch: {
        s() {
            this.doSearch(this.s);
        }
    },
    methods: {
        doSearch: function(s) {
            if (s && s.length > 1) {
                axios.get('/media/search?s=' + s).then((res) => {                                        
                    this.searchResult = res.data;
                });
            }
        },
        reloadSearch: function() {
            this.doSearch(this.s);
        } 
    }
}
</script>