<template>
    <div> 
        Versjon: {{version.buildYear}}.{{version.buildWeek}}.{{version.buildRevision}} {{version.buildTag}}
    </div>
</template>

<script>
import buildVersion from '@/build-version.json';

export default {

    data() {
        return {
            version: buildVersion,
        }
    }
}
</script>