<template>
    <div class="py-4 px-6">       
        <h1>Ainou Mini</h1>
        <MiniInstructions />    
    </div>
</template>

<script>
import MiniInstructions from '@/components/MiniInstructions.vue';

export default {
    components: {
        MiniInstructions
    }
}

</script>

<style scoped>
h1 {
    @apply text-3xl tracking-wide font-semibold;
}
.heading {
    @apply font-semibold text-xl mb-4;
}
</style>