<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">

            <div v-if="payUrl" class="w-full h-screen relative">
                <div class="absolute w-full h-screen bg-white text-center pt-16 text-xl tracking-wider">
                    Vennligst vent...
                </div>                 
                <iframe :src="payUrl" class="absolute w-full" :class="debug.active ? 'h-96' : 'h-screen'" />
                <div v-if="debug.active" class="absolute mt-96 p-2 text-sm overflow-auto">                    
                    <div v-html="debug.status"></div>
                    <div v-html="debug.info"></div>
                </div>
                <svg class="absolute right-2 top-2 h-10 w-10 cursor-pointer" viewBox="0 0 24 24" @click="closePay()">
                    <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                </svg>                                                                                                       
            </div>

            <div v-else class="modal-content text-center">

                <div class="flex justify-end -mr-2 -mt-2 mb-0" @click="close(false)">
                    <svg class="h-8 w-8" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>                    
                </div>

                <div class="modal-header">
                    {{ category.name }}
                </div>
                <div v-html="category.desc"></div>

                <div class="my-6 rounded-xl border border-gray-400 bg-green-100 py-3">
                    <div class="text-sm text-gray-600">
                        Versjon {{ category.boardCategory ? catVersion(category.newVersionDate) : catVersion(category.versionDate) }}
                    </div>
                    <div class="mt-1 text-lg font-semibold">
                        <span v-if="isFree">Gratis</span><span v-else>NOK {{ category.price }},-</span>
                    </div>                        
                    <div v-if="category.boardCategory" class="mt-1">
                        {{ category.newVersionQuestionDiff }} nye spørsmål
                    </div>
                    <div v-else-if="!isNewsCat">
                        {{ roundQuestionCount(category.questionCount) }} spørsmål
                    </div>
                </div>

                <div v-if="!category.hasCard" class="mb-6 italic text-sm">
                    Denne kategorien ble lansert etter at spillet var produsert. Bruk medfølgende blankt kort når du spiller med denne kategorien.
                </div>
                <div v-if="ageLimit" class="mb-6">
                    <span class="font-semibold">NB!</span> Denne kategorien inneholder spørsmål for aldersgruppen {{ageLimit}}
                </div>

                <div class="mb-4"> 
                    <div class="text-sm">Kvittering sendes til:</div>
                    <div v-if="emailOK && !editEmail">{{email}} 
                        <span @click="editEmail=true" class="cursor-pointer" title="Endre">
                            <fa-icon icon="pencil-alt"/>
                        </span>
                    </div>
                    <div v-else class="flex gap-x-2 items-center">
                        <input  type="email" class="w-full" v-model="email">
                    </div>                    
                    <div v-if="!emailOK" class="mt-2 px-2 py-1 bg-red-200 border border-gray-400 rounded-lg text-sm">Angi en e-post som skal motta kvittering</div>
                </div>
                
                <div v-if="connectedCode" class="mb-3">
                    <label>
                        <input type="checkbox" v-model="addToConnected">
                        Kjøp til koblet brettspill
                    </label>
                    <span class="ml-1 rounded-full text-white bg-gray-800 px-2 font-semibold" @click="showHelp = !showHelp">?</span>
                    <div v-if="showHelp" class="text-sm text-left">
                        Velg denne for å kjøpe kategorien til koblet brettspill. Da vil den kunne brukes i begge spillene men vil ikke
                        være tilgjengelig i mini-utgaven dersom du velger å oppheve koblingen med brettspillet.                    
                    </div>
                </div>

                <button class="btn mb-4 px-6 py-2" @click="openPay()"> 
                    {{ isFree ? 'Legg til' : 'Kjøp' }} {{ category.boardCategory ? 'oppdatering' : 'kategori' }}
                </button>        

                <div class="py-2 text-sm"> 
                    <!--<input type="checkbox" v-model="termsAccepted" id="vilkar" class="cursor-pointer">
                    <label for="vilkar" class="cursor-pointer"> Jeg har lest og godtar </label>-->
                    <div>
                        <a href="/betingelser.pdf" target="_blank" class="underline">Betingelser for dette kjøpet</a>
                    </div>
                    <div class="mt-1">
                        Happy Dice AS | Orgnr: 927 270 544 MVA Foretaksregisteret
                    </div>
                </div>     

                <img src="/images/visa-master-vipps.png" alt="Betalingsmetoder" class="mx-auto">          
                
            </div>
        </div>
    </teleport>    
</template>

<script>
import { catVersion, formatDate } from '@/utils.js';
import axios from 'axios';

export default {
    computed: {
        isFree() {
            return this.category.price === 0;
        },
        emailOK() {
            return this.email?.trim().length > 4 && this.email?.indexOf('@') > -1 && this.email?.indexOf(' ') === -1;
        }
    },
    data() {
        return {
            orderId: 0,
            show: false,
            category: null,
            isNewsCat: false,
            uuid: null,
            ageLimit: null,
            resolve: null,
            reject: null,
            payUrl: null,
            checkPaymentSec: 1,
            //termsAccepted: false,
            debug: {
                active: false,
                status: '',
                counter: 0,
                info: '',                
            },     
            editEmail: false,
            email: '',        
            connectedCode: null,    
            addToConnected: false,   
            showHelp: false,
        }
    },
    methods: {
        catVersion,
        open: function(category, uuid, ageLimit, isNewsCat, connectedCode) {                       
            this.category = category;        
            this.isNewsCat = isNewsCat;
            this.connectedCode = connectedCode;
            this.uuid = uuid;
            this.ageLimit = ageLimit;
            this.show = true;
            this.debug.info = '';
            this.getBoardEmail();
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });           
        },
        close: function(buySuccess) {
            this.show = false;
            this.resolve(buySuccess); 
        },
        openPay: function() {
            if (this.editEmail && this.emailOK) {    // set new board email first                        
                const data = {
                    uuid: this.uuid,
                    email: this.email,  
                };                     
                axios.post('/board/email', data).then(() => {
                    this.editEmail = false;
                    this.openPay();
                }).catch(() => {
                    console.log('set board email failed');
                });                           
            }
            else if (this.emailOK) {
                let catClone = JSON.parse(JSON.stringify(this.category));
                catClone.fromDate = formatDate(catClone.fromDate);
                catClone.versionDate = formatDate(catClone.versionDate);
                catClone.newVersionDate = formatDate(catClone.newVersionDate);                 
                let data = {
                    addToConnected: this.addToConnected,
                    uuid: this.uuid,
                    category: catClone,
                };    
                this.debug.counter = 0;        
                axios.post('/checkout/order/category', data).then((res) => {                
                    this.payUrl = res.data.pay_url;    
                    this.orderId = res.data.order_id;  
                    this.checkPayment();
                }).catch((err) => {                
                    console.error(err);
                    this.addDebugInfo(err.message);
                });
            }
        },
        checkPayment: function() {      
            this.setDebugStatus('waiting for payment... (' + (this.debug.counter++) + ')');
            axios.get('/category/checkpayment/' + this.orderId).then((res) => {                                
                if (res.data.accepted) {
                    this.addDebugInfo('payment ok!');
                    this.closePay();
                    this.close(true);
                }
                else if (this.payUrl) {
                    setTimeout(() => {
                        this.checkPayment();
                    }, this.checkPaymentSec*1000);
                }
                else {
                    this.addDebugInfo('payment check stopped!');
                    this.addDebugInfo(JSON.stringify(res.data));    
                }              
            });            
        },
        closePay: function() {
            this.payUrl = '';
        },
        setDebugStatus: function(status) {
            this.debug.status = status;
        },
        addDebugInfo: function(info) {
            this.debug.info += info + '<br>';
        },
        getBoardEmail: function() {
            axios.get('/board/email?uuid=' + this.uuid).then(res => {
                this.email = res.data.email;
                if (!this.email || !this.email.trim().length) {
                    this.editEmail = true;
                }
            });
        },
        roundQuestionCount: function(count) {            
            return Math.max(50, Math.round(count / 50)*50);
        }
    }
}
</script>