<template>
    <header class="text-white fixed w-full sm:flex justify-between sm:px-4 sm:py-6 z-10 shadow-2xl items-center" style="background-color: #12355A">
    
        <div class="flex items-center justify-between py-3 sm:py-0 px-4 sm:px-6 lg:px-12 xl:px-24">
            
            <router-link :to="{name: 'home'}">
                <!--div class="cursor-pointer" @click="scrollTo('top')"-->
                <img src="/images/ainou.png" alt="Ainou" class="w-32 h-9 sm:w-48 sm:h-12">
            </router-link>

            <div class="flex">
                <div class="sm:hidden mr-6">
                    <a href="https://www.instagram.com/brettspilletainou/"><img src="/images/instagram_icon.png" class="w-10 h-10" alt="Instagram"></a>
                </div>             

                <div class="sm:hidden flex">
                    <div class="mr-3">
                        <ShoppingCartBtn v-if="showShoppingCartBtn" @click="showCart()" :count="itemsInShoppingCart" />    
                    </div>                
                    <button @click="toggleMenu()" type="button" class="block text-gray-100 hover:text-gray-200 focus:shadow-none">
                        <svg class="h-8 w-8 fill-current" viewBox="0 0 24 24">
                            <path v-if="isOpen" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                            <path v-if="!isOpen" fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                        </svg>
                    </button>
                </div>                                 
            </div>
            
        </div>
            
        <div class="flex items-center">
            <div class="hidden sm:block mr-6">
                <a href="https://www.instagram.com/brettspilletainou/"><img src="/images/instagram_icon.png" class="w-10 h-10" alt="Instagram"></a>
            </div> 
            <nav id="menu" :class="isOpen ? 'block' : 'hidden'" class="sm:flex pb-4 sm:pb-0 text-xl sm:mr-8 md:mr-12 lg:mr-24"> 
                <div class="menuItem" @click="openContactForm()">Kontakt oss</div>
                <div class="menuItem" @click="openFAQ()">FAQ</div>
                <div class="ml-4" :class="isOpen ? 'hidden' : 'block'">
                    <ShoppingCartBtn v-if="showShoppingCartBtn" @click="showCart()" :count="itemsInShoppingCart" />
                </div>
            </nav>
        </div>

    </header>    

    <FAQ ref="faq" />
    <ContactModal ref="contactModal" />
</template>

<script>
import ShoppingCartBtn from '@/components/ShoppingCartBtn.vue';
import ContactModal from '@/components/ContactModal.vue';
import FAQ from '@/components/FAQ.vue';

export default {
    emits: [
        'scroll-to', 'show-cart'
    ],
    components: {
        ShoppingCartBtn,
        FAQ,
        ContactModal,
    },
    data() {
        return {            
            isOpen: false,
        }
    },
    computed: {
        itemsInShoppingCart() {
            return this.$store.getters.cart.length;
        },
        showShoppingCartBtn() {
            return this.itemsInShoppingCart > 0;
        },
    },
    methods: {
        closeMenu() {
            this.isOpen = false;
        },
        toggleMenu() {
            this.isOpen = !this.isOpen;   
        },
        scrollTo: function(id) {
            this.$emit('scroll-to', id);
            this.closeMenu();
        },
        openFAQ: function() {       
            this.$refs.faq.open();                    
            this.closeMenu();
        },
        openContactForm: function() {
            this.$refs.contactModal.open();
            this.closeMenu();
        },
        showCart: function() {            
            this.$emit('show-cart'); 
            this.closeMenu();            
        }
    }    
}
</script>

<style scoped>
    .menuItem {
        @apply mr-6 ml-4 cursor-pointer py-2 sm:py-0;
    }
</style>