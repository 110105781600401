<template>
    <div> 
        <div class="text-sm mb-2">
            <!--Total: {{ questionsObj.totCount }} -->
            <span v-if="questionsObj.questions && (questionsObj.questions.length < questionsObj.totCount)">Showing: {{ questionsObj.questions.length }}</span>
        </div>
        <div v-for="(q, i) in questionsObj.questions" :key="i" class="border-t py-2">
            <Question 
                :q="q" 
                :showCategory="showCategory"
                :category="category"
                v-on:edit="editQuestion" 
                v-on:delete="deleteQuestion" 
                v-on:un-approve="unApprove" 
                v-on:set-approve-date="setApproveDate"
                v-on:open-picture="openPicture" 
                v-on:open-audio="openAudio" 
                v-on:open-video="openVideo" 
            />
        </div>       
        <div v-if="questionsObj.hasMore" class="mt-3 underline cursor-pointer" @click="fetchMore()">
            More questions
        </div> 
    </div>

    <MediaModal ref="mediaModal" :edit="false" />  
    <EditQuestion ref="editQuestionDlg" :edit="true" />

</template>

<script>
import axios from 'axios';
import Question from '@/components/Question.vue';
import EditQuestion from '@/components/EditQuestion.vue';
import MediaModal from '@/components/MediaModal.vue';

export default {
    props: {
        questions: Object,
        category:  Object,
        ageGroup: Number,
        showCategory: Boolean,
    },
    data() {
        return {
            questionsObj: {}
        }
    },
    components: {
        Question,
        EditQuestion,
        MediaModal
    },
    watch: {
        questions: function(newVal) {
            this.questionsObj = newVal;
        },
    },
    mounted() {
        this.questionsObj = this.questions;
    },
    emits: ['edit'],
    methods: {
        openPicture: function(pictureId, mediaAttribution) {
            this.$refs.mediaModal.openPicture(pictureId, mediaAttribution);
        },       
        openAudio: function(audioId) {
            this.$refs.mediaModal.openAudio(audioId);
        },
        openVideo: function(videoId) {
            this.$refs.mediaModal.openVideo(videoId);
        },
        editQuestion: function(question) {
            this.$refs.editQuestionDlg.open(question).then(() => {
                this.$emit('edit');
            });
        },
        deleteQuestion: function(questionId) {
            if (confirm('Delete question?')) {
                axios.post('/question/delete/' + questionId).then(() => {
                    this.$emit('edit');  // refresh list
                });
            }
        },
        unApprove: function(questionId) {            
            axios.post('/question/unapprove/' + questionId).then(() => {
                this.$emit('edit');   // refresh list
            });
        },
        setApproveDate: function(questionId, approveDate) {
            const data = {
                id: questionId,
                approveDate: approveDate,
            }
            axios.post('/question/setapproved', data).then(() => {
                this.$emit('edit');   // refresh list
            });
        },
        fetchMore: function() {
            const params = '?page=' + (++this.questionsObj.page) + '&age=' + this.ageGroup;
            axios.get('/questions/' + this.questionsObj.categoryId + params).then((res) => {
                this.questionsObj.questions = this.questionsObj.questions.concat(res.data.questions);
                this.questionsObj.hasMore = res.data.hasMore;            
            });
        },
    }
}
</script>