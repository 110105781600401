<template>
    <div class="p-6 sm:p-10 bg-blue text-white min-h-screen">
        <div class="font-semibold mb-4 text-2xl">
            Happy Dice AS<br>Personvernerklæring
            <div class="text-sm font-normal mt-1">Gyldig fra 23.09.2021</div>
        </div>
        

        <div class="paragraph">
            <div class="heading">Innledning</div>
            Happy Dice AS («Happy Dice») samler inn personopplysninger om deg når du besøker våre websider, er kunde
            eller underleverandør til oss, eller på annen måte tar kontakt med oss. Denne personvernerklæringen beskriver de 
            tilfeller vi behandler dine personopplysninger og på hvilket grunnlag.
            Formålet med personvernerklæringen er å informere deg om vår behandling av dine
            personopplysninger i samsvar med de krav som følger av personopplysningsloven som omfatter
            personvernforordningen (GDPR).
        </div>
        <div class="paragraph">
            <div class="heading">Behandlingsansvarlig</div>
            Happy Dice ved styret er behandlingsansvarlig for personopplysninger vi behandler. Det daglige
            behandlingsansvaret knyttet til vår virksomhet er delegert til selskapets daglig leder. Utfyllende
            kontaktinformasjon finner du nederst i dette dokumentet. Alle henvendelser vedrørende personvern
            kan sendes til post@happydice.no
        </div>
        <div class="paragraph">
            <div class="heading">Behandlingsformål</div>

            <div class="subHeading">Tilby og drifte system </div>
            Når du kjøper brettspillet Ainou av Happy Dice AS må du oppgi navn, adresse, e-postadresse og evt. mobilnummer.
            Vi behøver disse opplysningene for å kunne sende produktet til deg, samt sende elektroniske kvitteringer på kjøp
            av spillet i tillegg til evt. flere spørsmålskategorier som kan kjøpes i appen. 
            Behandlingsgrunnlaget følger av personvernforordningens artikkel 6 nr. 1 bokstav f), 
            hvor vår berettigede interesse for å kunne følge opp og administrerer våre kundeforhold, veier tyngre enn den enkeltes personvern.

            <div class="subHeading">Analyse av bruk</div>
            Happy Dice utfører analyse av besøk på nettsiden med hjelp av Google Analytics. Din unike internetadresse (ip-adresse) kan anses som en personopplysning. 
            Denne ip-adressen anonymiseres før overføring til Google. Dermed lagres ingen personopplysninger hos Google, en praksis som er i tråd
            med Datatilsynets anbefaling. For å kunne analysere besøk på websidene benytter Happy Dice cookies.
            Behandlingsgrunnlaget er ditt samtykke, jf. ekomloven § 2-7b. Samtykket baserer seg på at du 
            gjennom innstillinger i din nettleser har akseptert cookies. Les mer om vår bruk av cookies og
            hvordan du kan skru dette av om du ønsker det: <a href="cookies">https://ainou.no/cookies</a>

            <div class="subHeading">Kommunikasjon og dialog</div>
            Kommuniserer du elektronisk med oss via e-post, lagres det personopplysninger i våre systemer
            for e-post. Opplysningene slettes regelmessig når de ikke lenger er nødvendig for formålet de er
            samlet inn for, eller så lenge vi er pålagt å lagre opplysningene i henhold til gjeldende lovverk.

            <div class="subHeading">Fakturaadministrasjon og administrasjon av leverandører</div>
            Kontaktpersoner hos underleverandører vil registreres i vårt regnskapssystem med informasjon
            nødvendig for å administrere avtaleforholdet og for å kunne betale fakturaer. Opplysninger som
            samles inn er navn, stilling, e-post og mobilnummer.
            Behandlingsgrunnlaget er vår berettigede interesse av å lagre personopplysninger nødvendige for å
            administrere leverandørforholdet, jf. personvernforordningens artikkel 6 nr. 1 bokstav f).
            Personopplysningene lagres i våre systemer så lenge du er ansatt hos en leverandør til Happy Dice.
            Likevel vil visse opplysninger kunne lagres utover dette, da bokføringsloven pålegger Happy Dice å
            oppbevare regnskapsbilag (som fakturer) i fem år etter avsluttet regnskapsår. Behandlingsgrunnlaget
            for slik lagring følger av personvernforordningen artikkel 6 nr. 1 bokstav c), rettslig forpliktelse.
        </div>
        <div class="paragraph">
            <div class="heading">Dine rettigheter</div>
            I henhold til gjeldende personvernlovgivning har du en rekke rettigheter i forbindelse med tilgang til
            og behandling av dine personopplysninger.
            Du har med de unntak som følger av personopplysningsloven § 16, rett til når som helst å be om
            innsyn i hvilke personopplysninger vi behandler om deg. Du kan også kreve korrigering/retting av
            dine personopplysninger dersom disse er unøyaktige eller ufullstendige. Under visse omstendigheter
            har du også rett til å kreve at vi sletter dine personopplysninger. Se mer om sletting nedenfor.
            Under visse omstendigheter har du også rett til å kreve at vi begrenser behandlingen av dine
            personopplysninger og/eller protestere mot behandlingen av dine personopplysninger.
            I visse situasjoner har du rett til å få utlevert (motta) i et strukturert, vanlig og maskinlesbart format,
            personopplysninger om deg som du har delt med Happy Dice.
            Henvendelser vedrørende dine rettigheter kan rettes til post@happydice.no. Se også annen
            kontaktinformasjon i bunnen av dokumentet.            
        </div>        
        <div class="paragraph">
            <div class="heading">Om retten til sletting</div>        
            Vi vil slette alle personopplysninger vi behandler om deg uten ugrunnet opphold etter at vi har
            mottatt din henvendelse og i samsvar med de frister som følger av personvernforordningen artikkel
            12. Opplysningene vil likevel ikke bli slettet om vi har annet lovmessig behandlingsgrunnlag og behov for
            fortsatt lagring av dine personopplysninger. Foreligger slikt behandlingsgrunnlag vil vi opplyse om 
            dette når du henvender deg til oss, med informasjon om behandlingsgrunnlaget og når
            opplysningene eventuelt vil kunne slettes.            
        </div>
        <div class="paragraph">
            <div class="heading">Om lagringstid</div>           
            Happy Dice lagrer personopplysninger så lenge vi har et behandlingsgrunnlag for personopplysningene, og
            ikke lenger enn hva som er nødvendig for å oppfylle det formål opplysningene ble samlet inn.
            Personopplysninger som lagres i tilknytning til regnskapsbilag beholdes i fem år etter foregående
            regnskapsår.
        </div>
        <div class="paragraph">
            <div class="heading">Sikkerhetstiltak</div>    
            Happy Dice har gjennomført nødvendige tekniske og organisatoriske tiltak for å sikre at
            personopplysninger vi har samlet inn sikres og behandles på en trygg måte. Vi gjennomfører
            regelmessige tester og vurderinger av sikkerheten i våre systemer.            
        </div>
        <div class="paragraph">
            <div class="heading">Overføring av personopplysninger til tredjepart</div>
            Happy Dice vil ikke overføre eller på annen måte dele dine personopplysninger med andre, med unntak av følgende forhold:
            
            <div class="subHeading">Nødvendig bistand fra underleverandører</div>
            For å kunne utøve vår virksomhet er vi avhengig av samarbeidspartnere som kan bistå med 
            drift av våre ikt-systemer og betalingsløsninger. Våre underleverandører vil kunne få tilgang til
            personopplysninger vi behandler som ledd i deres leveranser. Alle våre
            databehandlere er lokalisert innenfor EØS-området, og er pålagt å følge gjeldende
            personvernlovgivning, herunder nødvendige tekniske og organisatoriske tiltak for å sikre at
            personopplysninger vi har samlet inn sikres og behandles på en trygg måte.
            
            <div class="subHeading">Overføring med bakgrunn i lov eller dom/kjennelse</div>
            Happy Dice vil overføre personopplysninger til offentlige myndigheter om dette pålegges med hjemmel i
            lov eller med rettskraftig dom eller kjennelse.
        </div>    
        <div class="paragraph"> 
            <div class="heading">Overføring av personopplysninger utenfor EØS-området</div>
            Happy Dice vil ikke overføre personopplysninger for de behandlingsformål som angitt i denne
            personvernerklæringen til land utenfor EØS-området.
        </div>
        <div class="paragraph"> 
            <div class="heading">Klageinstans</div>        
            Du kan klage til Datatilsynet dersom du mener vår behandling av dine personopplysninger ikke er i
            henhold til denne personvernerklæringen eller gjeldende personvernlovgivning.
            Oppdatert kontaktinformasjon til Datatilsynet finner du her: 
            <a href="https://www.datatilsynet.no/om-datatilsynet/kontakt-oss/">https://www.datatilsynet.no/om-datatilsynet/kontakt-oss</a>
        </div>
        <div class="paragraph"> 
            <div class="heading">Endringer i personvernerklæringen</div>   
            Vi forbeholder oss retten til å oppdatere og endre denne personvernerklæringen ved behov, og når
            det er nødvendig for å tilpasse til personvernlovgivningen. Du vil alltid finne gjeldende versjon av vår
            personvernerklæring her på våre websider.            
        </div>      
        <div class="paragraph"> 
            <div class="heading">Vår kontaktinformasjon</div>            
            Happy Dice AS<br>
            Organisasjonsnummer: 927 270 544 MVA<br>
            E-post: post@happydice.no<br>
            Adresse: Teiealleen 28, 2030 Nannestad<br>                        
        </div>

        <button class="btn" @click="$router.push('/')">Tilbake</button>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    .paragraph {
        @apply pb-6 border-t border-gray-500 pt-4;
    }
    .paragraph .heading {
        @apply font-semibold text-lg mb-1;
    }
    .paragraph .subHeading {
        @apply font-semibold italic mt-4 mb-1;
    }
    a {
        @apply underline;
    }
</style>