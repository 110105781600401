<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">
            <div class="modal-content max-w-sm" :class="{'text-xl': textLarge }">

                <div v-if="closeX" class="flex justify-end -mr-2 -mt-2 mb-0" @click="close(false)">
                    <svg class="h-8 w-8" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>                    
                </div>

                <div class="modal-header" v-if="heading" v-html="heading"></div>
                <div class="pt-4 pb-6 text-center" v-if="text" v-html="text"></div>                
                <div class="modal-footer flex justify-evenly">
                    <div v-if="okBtn">
                        <button class="btn py-2 px-6" @click="close(true)">{{ okBtn }}</button>
                    </div>
                    <div v-if="confirmMode">
                        <button class="btn py-2 px-6" @click="close(false)">{{ cancelBtn }}</button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
export default {
    props: {
        textLarge: Boolean,
        confirmMode: Boolean,
        closeX: Boolean
    },
    data() {
        return {
            show: false,
            heading: '',
            text: '',
            okBtn: '',
            cancelBtn: '',
            resolve: null,
            reject: null,
        }
    },
    methods: {
        open: function(heading, text, okBtn, cancelBtn) {
            this.heading = heading;
            this.text = text;
            if (okBtn) {
                this.okBtn = okBtn;
            }
            if (cancelBtn) {
                this.cancelBtn = cancelBtn;
            }
            this.show = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });            
        },
        close: function(ok) {            
            this.resolve(ok);                        
            this.show = false;
        }
    }
}
</script>