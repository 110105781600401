<template>
    <div>
        <div v-if="sales">
            <table>
                <tr><td>Games sold:</td><td class="text-right">{{ sales.boards }}</td></tr>
                <tr><td>Categories sold:</td><td class="text-right">{{ sales.addCat }}</td></tr>
                <tr><td>Categories updated:&nbsp;</td><td class="text-right">{{ sales.updateCat }}</td></tr>
                <tr><td>Total sales:</td><td class="text-right">{{ sales.totalSum }}</td></tr>
            </table>
        </div>
        <div class="mt-6">
            <div>Total sales pr. day</div>
            <input type="date" v-model="salesDate" @change="getSalesOnDate">
            <div class="ml-1 mt-3" v-if="salesOnDate">                
                <table class="salesOnDate">
                    <tr>
                        <th></th><th>Amount</th><th>Ink. mva</th>
                    </tr>
                    <tr>
                        <td class="text-left">Boards</td>                        
                        <td>{{salesOnDate.amountBoard}}</td>
                        <td>{{salesOnDate.vatBoard}}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Categories</td>                        
                        <td>{{salesOnDate.amountCat}}</td>
                        <td>{{salesOnDate.vatCat}}</td>
                    </tr>     
                    <tr class="font-semibold border-t border-b-2 border-black">
                        <td class="text-left">Total</td>                        
                        <td>{{salesOnDate.amountBoard + salesOnDate.amountCat }}</td>
                        <td>{{salesOnDate.vatBoard + salesOnDate.vatCat}}</td>
                    </tr>                                     
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {

    data() {
        return {
            sales: null,
            salesDate: '',
            salesOnDate: null,
        }
    },
    mounted() {
        this.getSales();
    },
    methods: {
        getSales: function() {
            axios.get('/sales').then((res) => {            
                this.sales = res.data;
            });
        },       
        getSalesOnDate: function() {            
            if (this.salesDate) {
                axios.get('/sales/date?d=' + this.salesDate).then(res => {                
                    this.salesOnDate = res.data;
                });
            }
        },
    },
}
</script>

<style scoped>
    table.salesOnDate {
        @apply text-right;
    }
    table.salesOnDate td {
        @apply px-3;
    }
    table.salesOnDate th {
        @apply font-normal text-center text-sm border-b border-black;
    }
    
</style>