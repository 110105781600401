<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">
            <div class="modal-content max-w-md sm:mt-3 relative">

                <div class="absolute right-4 -mt-2 mb-0 z-20 cursor-pointer" @click="close(false)">
                    <svg class="h-8 w-8" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>                    
                </div>

                <form @submit="submitContactForm" @submit.prevent>
                    <div class="modal-header">
                        Kontakt oss
                    </div>                
                    <div class="flex flex-col gap-y-2">                    
                        <div>
                            <input class="w-full" type="text" placeholder="Navn" autocomplete="name" v-model="contactForm.name">
                        </div>
                        <div>
                            <input class="w-full" type="email" placeholder="E-post" autocomplete="email" v-model="contactForm.email" required>
                        </div>
                        <div>
                            <textarea class="h-24 resize-none w-full" placeholder="Din melding til oss" v-model="contactForm.message" required></textarea>
                        </div>
                    
                        <div v-if="contactOk" class="bg-green-50 text-green-800 mb-4 p-2 border rounded border-gray-200">
                            Takk for din henvendelse! Du får svar fra oss innen kort tid 😊
                        </div>
                    </div>
                             
                    <div class="modal-footer flex justify-center gap-x-4">
                        <button class="btn py-2 px-6" type="submit">Send</button>
                        <button class="btn btn-secondary py-2 px-6" @click="close()">Lukk</button>
                    </div>
                </form>   

            </div>
        </div>
    </teleport>
</template>

<script>
import axios from 'axios';

export default {

    data() {
        return {
            show: false,
            contactOk: false,
            contactForm: {
                name: '',
                email: '',
                message: '',
            },            
        }
    },
    methods: {
        open: function() {
            this.contactOk = false;
            this.show = true;
        },
        close: function() {
            this.show = false;
        },
        submitContactForm: function() {
            this.contactOk = false;
            axios.post('/contact', this.contactForm).then(() => {
                this.contactForm.name = '';
                this.contactForm.email = '';
                this.contactForm.message = '';
                this.contactOk = true;
            }).catch(() => {                
                console.log('contact Error!');
            });
        }        
    }
}

</script>