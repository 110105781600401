<template>
    <div class="flex flex-col justify-center w-full">
        <div ref="imgParent" class="relative mx-auto">                
            <div class="absolute">
                <div class="flex" v-for="(y,r) in gridY" :key="r">
                    <div v-for="(x,c) in gridX" :key="c" 
                        :ref="'block' + (c+(r*gridX))" 
                        :style="{ width: blockWidth + 'px', height: blockHeight + 'px', backgroundColor: maskColor }"
                        >
                    </div>
                </div>
            </div>      
            <img :src="imgUrl" class="mx-auto max-h-64" alt="">          
        </div>
        <div v-if="subText" v-html="subText" class="text-white font-semibold text-center"></div>    
    </div>
</template>

<script>

export default {
    props: {
        imgUrl: String,
        gridX: Number,  // horizontal block count
        subText: String,
        sound: Object,
        delay: Number,
    },
    data() {
        return {
            maskColor: 'rgba(31,41,55,1)',
            shownColor: 'rgba(75,85,99,1)',
            transColor: 'rgba(0,0,0,0)',
            duration: 1500,
            blockWidth: 0,
            blockHeight: 0,
            gridY: 0,
            intervalRef: null,
        }
    },
    created() {
        let img = new Image();
        img.src = this.imgUrl;
        img.onload = () => {
            this.calculateBlocks();                    
            this.$nextTick(() => {
                setTimeout(() => {
                    this.startAnimation();
                }, this.delay*1000);
            });
        }
    },
    unmounted() {
        clearInterval(this.intervalRef);
    },
    methods: {
        calculateBlocks: function() {
            let w = this.$refs.imgParent?.clientWidth;    
            let h = this.$refs.imgParent?.clientHeight;    
            //console.log(w, h);        
            let diffPercent = Math.round((w-h)/w*100);
            //console.log('w/h diff: ' + diffPercent + '%');

            this.blockWidth = Math.round(w/this.gridX);
            if (this.blockWidth*this.gridX < w) {
                this.blockWidth++;
            }

            let gridDiff = Math.abs(diffPercent) > 30 ? 1 : 0; 
            if (diffPercent > 0) {
                gridDiff = -gridDiff;
            }
            //console.log('grid diff: ' + gridDiff);
            this.gridY = this.gridX + gridDiff;
            this.blockHeight = Math.round(h/this.gridY);
            if (this.blockHeight*this.gridY < h) {
                this.blockHeight++;
            }            
            //console.log('grid', this.gridX, this.gridY);
        },
        startAnimation: function() {
            let blockOrder = [];
            let blockCount = this.gridX * this.gridY;
            for (let i = 0; i < blockCount; i++) {
                blockOrder.push(i);
            }            
            this.shuffle(blockOrder);

            let counter = 0;
            let prevBlock = null;
            this.intervalRef = setInterval(() => {                
                if (prevBlock) {
                    prevBlock[0].style.backgroundColor = this.shownColor;
                    if (counter >= blockCount) {
                        clearInterval(this.intervalRef);  // stop animation
                        this.sound?.play('beep');                      
                        for (let i = 0; i < blockCount; i++) {  // clear all blocks (show picture)
                            let block = this.$refs['block' + i];
                            if (block) {
                                block[0].style.backgroundColor = this.transColor;
                            }
                        }                                                
                    }                    
                }                
                let block = this.$refs['block' + blockOrder[counter++]];                
                if (block) {
                    block[0].style.backgroundColor = this.transColor;
                }                
                prevBlock = block;
            }, this.duration);
        },
        shuffle: function(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        },   
    } 
}

</script>