<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal">
            <div class="modal-content max-w-xl sm:my-2">

                <div class="flex justify-between">
                    <div class="font-semibold mb-2">Edit category</div>
                    <svg class="h-10 w-10 cursor-pointer -mt-2" viewBox="0 0 24 24" @click="close()">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>  
                </div>

                <label>Name <fa-icon v-if="category.travel" icon="plane-departure" /></label>
                <input type="text" v-model="category.name" class="w-full">            

                <label>Description</label>
                <textarea v-model="category.desc" class="w-full h-24 resize-none"></textarea>          

                <label>Age groups</label>
                <select v-model="category.minAgeGroup" class="w-full">
                    <option value="1">All</option>
                    <option value="2">13+</option>
                    <option value="3">16+</option>
                </select>

                <label>Price</label>
                <input type="number" v-model="category.price" class="w-full">          

                <label>Version</label>
                <input type="date" v-model="category.versionDate" class="w-full" :readonly="!canEditDate">     

                <div class="mb-3">
                    Purchase from date: {{category.fromDate}} ({{category.purchaseQuestionCount}} questions)
                </div>

                <div>
                    <input type="checkbox" id="new" v-model="category.isNew">
                    <label for="new" class="ml-2">Display 'New' label</label>                
                </div>
                
                <div>
                    <input type="checkbox" id="active" v-model="category.active" :disabled="!canActivate">
                    <label for="active" class="ml-2">Active</label>
                </div>

                <div class="mt-4">
                    <button class="btn" @click="updateCategory()">Save</button>
                </div>
            </div>
         
        </div>
    </teleport>                
</template>

<script>
import axios from 'axios';
import { htmlDate } from '@/utils.js';

export default {
    computed: {
        canEditDate() {
            return this.category.id != process.env.VUE_APP_NEWS_ID;
        },
        canActivate() {
            //return this.category.id != process.env.VUE_APP_TRAVEL_COMMON_ID;
            return !this.category.travel;
        },
    },
    data() {
        return {
            show: false,
            category: null,
            resolve: null,
            reject: null,            
        }
    },    
    methods: {
        htmlDate,
        open: function(cat) {                        
            this.category = cat;      
            this.category.fromDate = htmlDate(cat.fromDate);
            this.category.versionDate = htmlDate(cat.versionDate);
            this.show = true;  
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });                           
        },
        close: function() {
            this.show = false;
            this.resolve();
        },   
        updateCategory: function() {
            axios.post('/category/edit', this.category).then(() => {             
                this.close();
            });
        },
    }
}
</script>

<style scoped>

input:not([type=search]), textarea, select {
    @apply mb-2;
}

</style>