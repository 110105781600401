import { createStore } from 'vuex';
import game from './game.js';
import axios from 'axios';

const store = createStore({
   
    modules: {
        game: game,
    },

    state() {
        return { 
            author: null,
            cart: [],
        }
    },
    mutations: {
        initializeStore(state) {

            state.author = JSON.parse(localStorage.getItem('author'));                       
            if (state.author) {
                axios.defaults.headers.common['Key'] = state.author.id + ':' + state.author.key;
            }
            
            const cart = localStorage.getItem('cart');
            if (cart) {      
                const now = new Date();
                const storedCartItems = JSON.parse(cart);        
                for (let i = 0; i < storedCartItems.length; i++) {   
                    const cartItem = storedCartItems[i];
                    const itemAgeHours = (now.getTime()-cartItem.time)/1000/60/60;
                    if (itemAgeHours < 24) {
                        state.cart.push(cartItem);
                    }
                }
            }            
        },
        setAuthor(state, author) {            
            state.author = {
                id: author.id,
                username: author.username,
                name: author.name,
                key: author.key,
                role: author.role
            };
            localStorage.setItem('author', JSON.stringify(state.author));
            this.commit('initializeStore');      
        },
        logoutAuthor(state) {
            state.author = null;
            localStorage.removeItem('author');
        },
        addToCart(state, product) {
            state.cart.push(product);
            this.commit('saveCart');
        },       
        removeFromCart(state, idx) {
            state.cart.splice(idx, 1);
            this.commit('saveCart');
        },
        emptyCart(state) {
            state.cart = [];
            this.commit('saveCart');
        },
        saveCart(state) {
            localStorage.setItem('cart', JSON.stringify(state.cart));
        }
    },
    getters: {
        author: state => state.author,
        cart: state => state.cart,
    }
    
});

export default store;