<template>
    <div class="overflow-x-auto">

        <div class="flex gap-x-4">
            <div class="text-xl font-semibold">Bestillinger av spill</div>
            <button class="btn mb-4" @click="getOrders()">Oppfrisk</button>
        </div>

        <div class="text-lg font-semibold mb-2">Initielle bestillinger ({{tempOrders.length}})</div>
        <div class="overflow-x-auto">
            <table v-if="tempOrders.length">
                <thead>
                    <tr>
                        <th>Ordre ID</th>
                        <th>Ordre tidspunkt</th>
                        <th>Fornavn</th>
                        <th>Etternavn</th>
                        <th>E-post</th>    
                        <th>Brett ID</th>
                        <th>Rabattkode</th>
                        <th>Beløp</th>
                        <th>Frakt</th>
                        <th></th>              
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, i) in tempOrders" :key="i">
                        <td>{{order.id}}</td>
                        <td>{{formatDateTime(order.orderTime)}}</td>
                        <td>{{order.firstName}}</td>
                        <td>{{order.lastName}}</td>
                        <td>{{order.email}}</td>        
                        <td class="whitespace-nowrap">{{order.boardIds}}</td>      
                        <td>{{order.discountCode}}</td>
                        <td class="text-right">{{order.amount}}</td>
                        <td class="text-right">{{order.shipping}}</td>      
                        <td class="cursor-pointer underline" @click="checkPayment(order.id)">Sjekk betaling</td>                     
                    </tr>
                </tbody>                        
            </table>
        </div>

        <div class="text-lg font-semibold mt-6 mb-2">Nye bestillinger ({{newOrders.length}})</div>
        <div class="overflow-x-auto">
            <table v-if="newOrders.length" class="new">
                <thead>
                    <tr>
                        <th>Ordre ID</th>
                        <th>Ordre tidspunkt</th>
                        <th>Fornavn</th>
                        <th>Etternavn</th>
                        <th>E-post</th>
                        <th>Mobilnr</th>
                        <th>Adresse</th>
                        <th>Postnr</th>
                        <th>Poststed</th>
                        <th>Brett ID</th>
                        <th>Rabattkode</th>
                        <th>Beløp</th>                    
                        <th>Frakt</th>
                        <th>MVA</th>
                        <th>Betaling</th>
                        <th>Henvisning</th>
                        <th>Kampanje</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, i) in newOrders" :key="i" @click="processOrder(order)">
                        <td>{{order.id}}</td>
                        <td>{{formatDateTime(order.orderTime)}}</td>
                        <td class="whitespace-nowrap">{{order.firstName}}</td>
                        <td class="whitespace-nowrap">{{order.lastName}}</td>
                        <td>{{order.email}}</td>
                        <td class="whitespace-nowrap">{{order.phoneNumber}} <fa-icon v-if="order.phoneNumber" icon="copy" class="ml-1" @click="copyToClipboard($event, order.phoneNumber)"/></td>
                        <td class="whitespace-nowrap">{{order.address}}</td>
                        <td>{{order.zip}}</td>
                        <td class="whitespace-nowrap">{{order.city}}</td>
                        <td class="whitespace-nowrap">{{order.boardIds.join(', ')}}</td>
                        <td>{{order.discountCode}}</td>
                        <td class="text-right">{{order.amount}}</td>
                        <td class="text-right">{{order.shipping}}</td>      
                        <td class="text-right">{{order.vatAmount}}</td>  
                        <td>{{order.payMethod}}</td>       
                        <td>{{order.referrer}}</td>    
                        <td>{{order.campaign}}</td>    
                    </tr>
                </tbody>
            </table>
        </div>        

        <div class="text-lg font-semibold mt-6 mb-2">Behandlet ({{processedOrders.length}})</div>
        <div class="overflow-x-auto">
            <table>
                <thead>
                    <tr>
                        <th>Ordre ID</th>
                        <th>Ordre tidspunkt</th>
                        <th>Behandlet</th>
                        <th>Fornavn</th>
                        <th>Etternavn</th>
                        <th>E-post</th>
                        <th>Mobilnr</th>
                        <th>Adresse</th>
                        <th>Postnr</th>
                        <th>Poststed</th>
                        <th>Brett ID</th>
                        <th>Rabattkode</th>
                        <th>Henvisning</th>
                        <th>Kampanje</th>
                        <th>Beløp</th>
                        <th>Frakt</th>      
                        <th>MVA</th>
                        <th>Betaling</th>       
                        <th></th>       
                        <th>Sporing</th>
                        <th>Behandlet av</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, i) in processedOrders" :key="i">
                        <td>{{order.id}}</td>
                        <td>{{formatDateTime(order.orderTime)}}</td>
                        <td>{{formatDateTime(order.processedTime)}}</td>
                        <td class="whitespace-nowrap">{{order.firstName}}</td>
                        <td class="whitespace-nowrap">{{order.lastName}}</td>
                        <td>{{order.email}}</td>
                        <td>{{order.phoneNumber}}</td>
                        <td class="whitespace-nowrap">{{order.address}}</td>
                        <td>{{order.zip}}</td>
                        <td class="whitespace-nowrap">{{order.city}}</td>
                        <td>{{order.boardIds.join(', ')}}</td>
                        <td>{{order.discountCode}}</td>
                        <td>{{order.referrer}}</td>    
                        <td>{{order.campaign}}</td>    
                        <td class="text-right">{{order.amount}}</td>
                        <td class="text-right">{{order.shipping}}</td>
                        <td class="text-right">{{order.vatAmount}}</td>  
                        <td>{{order.payMethod}}</td> 
                        <td class="underline cursor-pointer" @click="sendReceipt(order.id)">Kvittering</td>
                        <td><a :href="'https://sporing.posten.no/sporing/' + order.trackingCode" target="_blank" class="underline cursor-pointer">{{order.trackingCode}}</a></td>
                        <td>{{order.processedBy}}</td>
                    </tr>
                </tbody>
            </table>        
        </div>

        <ProcessOrderModal ref="orderDlg" />
    </div>    
    
</template>

<script>
import axios from 'axios';
import ProcessOrderModal from '@/components/ProcessOrderModal.vue';
import { formatDateTime } from '@/utils.js';

export default {
    components: {
        ProcessOrderModal,
    },
    data() {
        return  {
            tempOrders: [],
            newOrders: [],
            processedOrders: [],
        }
    },
    created() {
        this.getOrders();
    },    
    methods: {
        formatDateTime,
        getOrders: function() {
            this.$root.showLoading(true);
            axios.get('/orders/board').then((res) => {
                this.tempOrders = res.data.temp_orders;
                this.newOrders = res.data.new_orders;                
                this.processedOrders = res.data.processed_orders.reverse();
                this.$root.showLoading(false);
                //console.log(this.processedOrders);
            });
        },
        processOrder: function(order) {
            this.$refs.orderDlg.open(order).then(() => {
                this.getOrders();  // update orders
            });
        },
        sendReceipt: function(orderId) {
            if (confirm('Send kvittering til kunden?')) {
                axios.post('/order/receipt/' + orderId, {}).then(res => {
                    console.log(res.data.success);
                });
            }
        },
        sendOrderConfirmation: function(orderId) {
            if (confirm('Send ordrebekreftelse til kunden?')) {
                axios.post('/order/confirmation/' + orderId, {}).then(res => {
                    console.log(res.data.success);
                });
            }
        },
        checkPayment: function(orderId) {
            this.$root.showLoading(true);
            axios.get('/board/checkpayment/' + orderId).then(res => {                
                if (res.data.accepted) {
                    this.getOrders();                    
                }
                this.$root.showLoading(false);
            });
        },
        copyToClipboard: function(e, value) {
            e.stopPropagation();
            navigator.clipboard.writeText(value);
        },
    },
}
</script>

<style scoped lang="postcss">
    table td, table th {
        @apply px-4 py-1;
    }
    table tr {
        @apply border-b border-gray-300;
    }
    table td {
        @apply align-top
    }
    table.new tbody tr {
        @apply hover:bg-green-50 cursor-pointer;
    }
</style>