<template>
    <div class="flex flex-col justify-center w-full items-center">         
        <Timer :start="time" v-on:timer-end="hide" class="mb-1" />
        <div v-if="player" class="mb-2 mx-12 text-white font-semibold text-center">
            Registrer så mange detaljer som mulig før tiden utløper                
        </div>
        <div class="relative">
            <div class="absolute w-full h-full rounded-xl z-30" ref="blocker"></div>            
            <img :src="imgUrl" class="mx-auto max-h-64 rounded-xl bg-white">    
        </div>
    </div>
</template>

<script>
import Timer from '@/components/Timer.vue';

export default {
    props: {
        imgUrl: String,
        time: Number,
        player: Boolean,
    },
    components: {
        Timer,
    },
    methods: {
        hide: function() {
            if (this.player) {
                this.$refs.blocker.classList.add('bg-gray-600');
            }
        }
    },
}
</script>