<template>
    <teleport to="#modals"> 
        <div v-if="show" class="flex justify-center">
            <div class="absolute z-20 top-0 w-full h-full bg-transparent"></div> <!-- block click on background while animating -->
            <div class="absolute z-20 duration-300 ease-out" :class="class_" :style="getStyle()"></div>
        </div>
    </teleport>
</template>


<script>
export default {
    
    data() {
        return {
            class_: '',
            show: false,
            questionCategory: null,
            extraDelay: 0,
            categoryTopClass: [
                'top-1/4',
                'top-1/4',
                'top-1/3',
                'top-1/2',
                'top-2/3',
                'top-3/4',
                'top-3/4'
            ],
            resolve: null,
            reject: null,
        }
    },
    methods: {
        open: function(questionCategory, extraDelay) {      
            this.questionCategory = questionCategory;        
            this.extraDelay = extraDelay;   
            this.animate('w-0 h-0 ' + this.getTopClass(), 'w-full h-full top-0');

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });             
        },     
        getTopClass: function() {
            return this.questionCategory ? this.categoryTopClass[this.questionCategory.idx] : '';
        },
        getStyle: function() {
            if (!this.questionCategory) {
                return '';
            }
            let style = {
                'background-color': this.questionCategory.color.bg,
            }
            return style;
        },
        close: function() {            
            this.animate('w-full h-full top-0', 'w-0 h-0 ' + this.getTopClass());           
        },             
        animate: function(startClass, endClass) {
            this.class_ = startClass;
            this.show = true;
            setTimeout(() => {
                this.class_ = endClass;
            },50);            

            setTimeout(() => {                                
                this.resolve();             
                this.show = false;                                 
            }, 400 + this.extraDelay);             
        },        
    }
}
</script>