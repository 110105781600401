<template>
    <div class="p-6 sm:p-10 bg-blue text-white min-h-screen">
        <div class="font-semibold mb-3 text-xl">
            Informasjonskapsler/cookies på våre nettsider
        </div>

        <div class="paragraph">
            En informasjonskapsel, ofte kalt cookie, er en liten tekstfil som lastes ned og lagres på din datamaskin når du åpner en nettside. 
            Dette er en standard teknologi som de aller fleste nettsider bruker. Hensikten med cookies er å forenkle og forbedre surfeopplevelsen for våre besøkende, 
            samt å gi oss statistikk som gjør at vi kan utvikle og forbedre sidene våre.
        </div>
        <div class="paragraph">
            Enkelte cookies er såkalte "session cookies". Disse slettes etter endt sesjon, det vil si når du lukker nettleseren din. 
            Andre cookies er "faste" eller såkalte "persistent cookies". Disse slettes ikke etter endt sesjon, men har en utløpsdato.    
        </div>
        <div class="paragraph">
            Ved å surfe på våre nettsider aksepterer du at vi kan sette cookies i din nettleser.
        </div>
        <div class="paragraph">
            <div class="mb-1">På våre websider bruker vi følgende cookies:</div>
            <div class="font-semibold mb-1">_ga</div>
            For å analysere trafikken på våre nettsider benytter vi oss av disse Google analytics cookies. De samler inn data for å gi statistikk som hjelper oss å forbedre nettsidene våre. 
            Vi samler inn denne informasjonen på en måte som ikke identifiserer noen. Vi prøver ikke å finne identiteten til dem som besøker nettsiden vår, 
            og vi forbinder ingen data som er samlet fra dette nettstedet med noen personlig identifiserbar informasjon fra andre kilder
        </div>
        <div class="paragraph">
            <div class="font-semibold mb-1">Deaktivere og slette cookies</div>

            I alle nettlesere kan du bestemme om du ønsker å akseptere cookies automatisk eller ikke. Du har også muligheter til å slette cookies når du måtte ønske. 
            For detaljer om dette se hjelp i egen nettleser eller følg den eksterne linken nedenfor for hjelp fra Post- og teletilsynet sine nettsider nettvett.no.
            Slik administrerer du cookies i nettleseren: 
            <a href="https://nettvett.no/slik-administrer-du-informasjonskapsler" class="underline">https://nettvett.no/slik-administrer-du-informasjonskapsler</a>
        </div>        

        <button class="btn" @click="$router.push('/')">Tilbake</button>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
    .paragraph {
        @apply pb-8;
    }
</style>