<template>
    <footer>
        <div class="border-t border-gray-400 border-b _mx-4 _lg:mx-16 mt-8 pb-2 mb-2 pt-4 text-center text-white bg-blue">
            <div class="mb-2 flex flex-wrap justify-evenly blue-bar"> 
                <span>post@happydice.no</span>
                <span>Orgnr: 927 270 544 MVA</span>      
                <span class="whitespace-nowrap">Teiealleen 28, 2030 Nannestad</span>          
            </div>             
            <!--<div class="text-sm sm:text-base -mx-6">
                <Copyright />
            </div>     -->
        </div>
        <div class="pt-1 text-center text-gray-700 text-sm">
            <div class="flex flex-wrap justify-center">Copyright &copy; <span v-if="currentYear > 2021">2021-</span>{{ currentYear }} Happy Dice AS              
                <span class="flex">
                    <span class="hidden sm:block">&nbsp;|&nbsp;</span>
                    <router-link to="cookies" class="footerLink">Cookies</router-link>&nbsp;|&nbsp;
                    <router-link to="personvern" class="footerLink">Personvernerklæring</router-link>&nbsp;|&nbsp;
                    <a href="/betingelser.pdf" class="underline" target="_blank">Kjøpsbetingelser</a>
                </span>
            </div>
        </div>
        <div class="mt-4 mb-2 sm:mb-4"> 
            <img src="images/visa-master-vipps-posten.png" alt="Betalingsmetoder" class="mx-auto">
        </div>     
    </footer>    
</template>

<script>
//import Copyright from '@/components/Copyright.vue';

export default {
    components: {
//      Copyright
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }    
}
</script>

<style scoped>
    .blue-bar span {
        @apply px-2;
    }
    .footerLink {
        @apply underline cursor-pointer;
    }
</style>