<template>
    <div>
        <Navbar v-on:showCart="showCart" />
        <div class="pt-20 sm:pt-32"></div> <!-- dummy for top margin -->
                
        <div v-if="board" class="px-4 sm:px-6 lg:px-12 xl:px-24 text-center sm:text-left">
            <div class="sm:flex">
                <div class="w-full sm:w-1/2">
                    <ImageCarousel2 :pictures="pictures" />
                </div>

                <div class="w-full sm:w-1/2 pl-0 sm:pl-8">
                    <h1 class="text-2xl sm:text-4xl">
                        Et unikt brettspill-konsept!                     
                    </h1>
                    <div class="mt-1 text-xl"> 
                        Ainou brettspill er et dynamisk kunnskapsspill for hele familien. 
                        Du velger selv hvilke kategorier du vil spille med og 
                        spørsmålene tilpasses alder automatisk!
                    </div>                
                    <div class="my-6 relative">
                        <div class="absolute right-0 sm:right-4 rotate-12 transform bg-blue text-white rounded sm:text-lg px-4 py-1">
                            Kun på nett!
                        </div>                    
                        <div class="flex flex-col items-center sm:flex-row font-semibold">
                            <span class="sm:mr-10 mb-4 sm:mb-0 text-red-600 text-5xl text-center">{{ board.price }},-</span>
                            <span v-if="board.count > 0" class="buyBtn" @click="buy()">Kjøp nå!</span>
                        </div>
                        <div class="mt-3 text-gray-700">
                            {{ getStockText(board.count) }} stk. på lager
                        </div>
                        <div class="mt-5 font-semibold text-blue">
                            <!--1-3 dager leveringstid i Sør-Norge, ellers i landet 3-5 dager. Vi sender samme dag ved bestilling før kl 15.-->
                            <!--NB! Vi har ferie 28. juli - 6. august. I denne perioden vil det være forsinkelse i utsendelser.-->
                        </div>
                    </div>
                    <div class="pt-2">
                        <div class="text-left text-lg relative bulletPoints">
                            <ul class="ml-4" v-for="(bp, i) in bulletPoints" :key="i">
                                <li>
                                    <span style="margin-left: -35px; margin-right: 8px;"><Circle :diameter="18" :outline="0" :fill="getBulletColor(i)" /></span><span v-html="bp"></span>
                                </li>
                            </ul>
                        </div>    
                    </div>
                </div>
            </div>

            <div class="mt-6 text-left">
                <h2>Spørsmålskategorier</h2>
                <div class="text-lg">
                    {{ ainou.board.categoryCount }} valgfrie kategorier med til sammen {{ ainou.board.questionCount }} spørsmål er inkludert når du kjøper spillet. 
                    Flere kategorier kan kjøpes enkeltvis i appen. Her kan du lese mer om de forskjellige kategoriene:
                </div>
                
                <div class="mt-4"> 
                    <div v-for="(cat,i) in categories" :key="i" class="mt-3">
                        <div v-if="cat.active && cat.price > 0" class="flex">
                            <div class="border-t pt-2 w-full">
                                <h3>{{cat.name}}</h3>
                                <div v-html="cat.desc" class="text-gray-700"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>     

            <div class="mt-8 p-4 sm:p-8 bg-yellow-50 rounded-xl">
                <div id="rules">               
                    <div class="text-lg font-semibold text-gray-700">
                        <a href="regelark.pdf"><span class="text-2xl">📄</span> Last ned regelarket her</a>
                    </div>
                </div>
            </div>          

        </div> 
    </div>
    <Footer />

    <BuyGame ref="buyDlg" :testMode="ainou.testMode" :categoriesIncluded="ainou.board.categoryCount" :allCategories="categories" :price="board.price" :shipping="board.shipping" :shippingTravel="travel.shipping" :maxPayTime="maxPayTime" :name="ainou.board.name" v-on:close="getProductInfo()" />

</template>

<script>
import axios from 'axios';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import ImageCarousel2 from '@/components/ImageCarousel2.vue';
import Circle from '@/components/Circle.vue';
import { getStockText } from '@/utils.js';
import BuyGame from '@/components/BuyGame.vue';


export default {
    components: {
        Navbar,
        Footer,
        ImageCarousel2,
        Circle,
        BuyGame,      
    },
    data() {
        return {
            maxPayTime: null,
            board: {},
            travel: {},
            categories: [],  
            pictures: [                      
                { img: '/images/product-2.png', border: false, alt: 'Eske forside' },
                { video: '/images/peiskos.mp4', autoplay: true, muted: true, loop: true, width: 400, alt: 'Video av spill' },
                { img: '/images/product-1.png', border: false, alt: 'Spillebrett og eske' },
                { img: '/images/product-10.jpg', border: false, alt: 'Anmeldelse terningkast 5' },
                { img: '/images/product-4.jpg', border: false, alt: 'App kategorier' },
                { img: '/images/product-5.jpg', border: false, alt: 'App spill' },                
                { img: '/images/product-6.jpg', border: false, alt: 'App spørsmål 1' },            
                { img: '/images/product-8.jpg', border: false, alt: 'App spørsmål 2' },
                { img: '/images/product-game1.jpg', border: true, alt: 'Spillebrett nærbilde 1' },
                { img: '/images/product-game2.jpg', border: true, alt: 'Spillebrett nærbilde 2' },
                { img: '/images/product-3.jpg', border: false, alt: 'Eske bakside' }
            ],   
            bulletColors: ['#EA332E','#F58230','#F4D051','#4A9C43','#1D5893','#A32488'],         
            bulletPoints: [
                'Interaktivt kunnskapsspill for hele familien',
                'Kombinasjon av fysisk spillebrett og app!',
                '2-6 spillere, i tillegg finnes en single-modus der man kan utfordre seg selv i appen',
                '10 valgfrie kategorier medfølger, i tillegg kan flere kategorier kjøpes i appen',
                '6 kategorier velges når man spiller, slik at det oppleves forskjellig hver gang og kan tilpasses spillernes interesser!',
                '5000 spørsmål inkludert, mange med bilde eller lydklipp',
                'Utvid spillet med flere kategorier direkte i appen',
                'Levende og dynamisk, med fortløpende oppdaterte spørsmål i «Aktuelt/nyheter»-kategorien',
                'Barn og voksne kan spille sammen, spillet tilpasser automatisk spørsmålene etter alder',
                '3 aldersgrupper: Barn (10-13), ungdom (13-16) og voksen (16+). Hver spiller velger sin egen alder i appen',
                'Støtter iPhone (iOS ver. 11.3+) og Android (ver. 4.4+, Chrome 53+). Trenger internettforbindelse',
            ],            
        }
    },
    created() {
        this.getProductInfo();
        this.getCategories();
    },
    methods: {
        getStockText,
        getProductInfo: function() {
            axios.get('/products').then(res => {
                this.board = res.data.board;
                this.travel = res.data.travel;
                this.maxPayTime = res.data.releaseTime;                
            });
        }, 
        getCategories: function() {
            axios.get('/question/categories').then(res => {
                this.categories = res.data.filter(c => !c.travel);                
                document.dispatchEvent(new Event('render-trigger'));
            });        
        },         
        getBulletColor: function(idx) {
            while (idx >= this.bulletColors.length) {
                idx -= this.bulletColors.length;
            }
            return this.bulletColors[idx];
        },        
        buy: function() {
            this.$refs.buyDlg.open(true);
        },    
        showCart: function() {
            this.$refs.buyDlg.open(false);
        },                      
    }
}

</script>