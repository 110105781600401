<template>
    <div class="border-b border-gray-500 py-1 font-semibold flex justify-between">
        <div>
            | <span @click="newPicture()" class="cursor-pointer">New Picture</span>
            | <span @click="newAudio()" class="cursor-pointer">New Audio</span>
            | <span @click="newVideo()" class="cursor-pointer">New Video</span>
            <!--| <router-link to="/admin/media/list">List</router-link> -->
            |
        </div>
        <div v-if="isAdmin" class="w-1/3 sm:w-max text-right">
            <form @submit.prevent="searchMedia()">
                <input type="search" placeholder="Search (min 2 char)" v-model="searchString" class="-mt-2 w-full">
            </form>
        </div>
    </div>    
    
    <div class="mt-2">
        <router-view :key="$route.fullPath" :isAdmin="isAdmin" />
    </div>    

    <MediaModal ref="mediaModal" :edit="true" /> 
</template>

<script>
import MediaModal from '@/components/MediaModal.vue';

export default {
    
    data() {
        return {
            searchString: '',
        }
    },
    props: {
        isAdmin: Boolean 
    },    
    components: {
        MediaModal
    },
    methods: {
        newPicture: function() {
            this.$refs.mediaModal.newPicture();
        },
        newAudio: function() {
            this.$refs.mediaModal.newAudio();
        },   
        newVideo: function() {
            this.$refs.mediaModal.newVideo();
        },                
        searchMedia: function() {
            this.$router.push({ name: 'mediaSearch', params: { s: this.searchString }});
        },
    }
}
</script>