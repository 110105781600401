<template>
    
    <div> 
        <div v-for="(msg, i) in messages" :key="i" class="py-2 border-b border-gray-200">
            <div class="font-semibold">
                <span class="mr-1 cursor-pointer text-blue-900" @click="editMessage(msg)">[Edit]</span>
                {{ msg.type.toUpperCase() }}
            </div>
            <div>
                [
                    {{ msg.fromTime }}
                    <span v-if="msg.toTime"> - {{ msg.toTime }}</span>
                ]
            </div>
            <div :class="{'line-through': !msg.active}" class="whitespace-pre-wrap">{{ msg.message }}</div>
        </div>
    </div>

    <teleport to="#modals"> 
        <div v-if="edit" class="modal">
            <div class="modal-content max-w-xl sm:my-2">
                <h3>Edit Message</h3>
                <form @submit.prevent="updateMessage">
                    <div class="mt-2 flex flex-col gap-y-4"> 
                        <textarea v-model="edit.message" class="w-full h-52 resize-none" required></textarea>
                        <div class="flex justify-between gap-x-4 items-center">
                            <div>
                                Type
                                <select v-model="edit.type">
                                    <option value="info">Info</option>
                                    <option value="alert">Alert</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-between gap-y-2">
                            <div>
                                From<br>
                                <input type="datetime-local" :value="getHtmlDateTime(edit.fromTime)" ref="fromTime" required>
                            </div>
                            <div>
                                To<br>                      
                                <input type="datetime-local" :value="getHtmlDateTime(edit.toTime)" ref="toTime">
                            </div>                        
                        </div>
                    </div>
                    <div class="mt-6 flex gap-x-2 justify-center">
                        <button class="btn" type="submit">Save</button>
                        <button class="btn" type="button" @click="closeEdit()">Cancel</button>
                    </div>
                </form>
            </div>        
        </div>
    </teleport>        
    
</template>

<script>
import axios from 'axios';

export default {
    
    data() {
        return {
            messages: [],
            edit: null,
        }
    },
    created() {
        this.getMessages();
    },
    methods: {
        getMessages: function() {
            axios.get('/allMessages').then((res) => {                
                this.messages = res.data;
            });
        },
        editMessage: function(msg) {
            this.edit = {...msg};  // clone
        },
        updateMessage: function() {
            this.edit.fromTime = this.$refs.fromTime.value;
            this.edit.toTime = this.$refs.toTime.value;        
            axios.put('/infomessage', this.edit).then(() => {
                this.closeEdit();
                this.getMessages();
            });
        },
        closeEdit: function() {
            this.edit = null;
        },
        getHtmlDateTime: function(s) {
            if (s) {
                const input = s.split(' ');  // dd.MM.yyyy HH:MM
                if (input.length === 2) {
                    const d = input[0];
                    const t = input[1];            
                    const date = d.split('.');
                    const time = t.split(':');
                    return date[2] + '-' + date[1] + '-' + date[0] + 'T' + time[0] + ':' + time[1];  // 2023-08-04T03:40                
                }
            }
        }            
    }
}
</script>