<template>
    
    <div class="flex">        
        <div class="pr-4 mr-4 border-r">
            
            <div class="flex flex-col gap-y-1">
                <button class="btn py-0" @click="getQuestions(activeCategory)">Refresh</button>
                <select @change="filterCategories" class="text-sm">
                    <option value="*">Show all categories</option>
                    <option value="std">Standard</option>
                    <option value="travel">Travel</option>
                </select>                
                <select @change="filterAge" class="text-sm">
                    <option value="-1">Show all questions</option>
                    <option value="0">10-99</option>
                    <option value="1">10-12</option>
                    <option value="2">13-15</option>
                    <option value="3">16-99</option>
                </select>
            </div>

            <div class="text-sm font-semibold my-2">Total count: {{totQuestionCount}}</div>

            <div>
                <div v-for="(cat, i) in categories" :key="i" @click="getQuestions(cat)" class="cursor-pointer my-2" :class="{'font-semibold': cat === activeCategory}">
                    <fa-icon v-if="cat.travel" icon="plane-departure" class="mr-2" />{{ cat.name }} ({{ cat.totQuestionCount }})
                </div>
            </div>
        </div>

        <QuestionList :questions="questions" :ageGroup="ageGroup" :category="activeCategory" v-on:edit="getQuestions(activeCategory)" />
    </div>      

</template>

<script>
import axios from 'axios';
import QuestionList from '@/components/QuestionList.vue';


export default {
    components: {                    
        QuestionList
    },
    props: {
        isAdmin: Boolean 
    },    
    computed: {
        categories() {                  
            return this.showCategories === '*' ? this.allCategories : this.showCategories === 'travel' ? this.allCategories.filter(item => item.travel) : this.allCategories.filter(item => !item.travel);
        },
        totQuestionCount() {
            let count = 0;
            this.categories.forEach(cat => {
                count += cat.totQuestionCount;
            });
            return count;
        }
    },
    data() {
        return {
            allCategories: [],
            questions: [],
            activeCategory: null,
            ageGroup: -1,
            showCategories: '*',
        }
    },
    created() {
        this.getCategories();
    },
    methods: {        
        getCategories: function() {
            axios.get('/question/categories?age=' + this.ageGroup).then(res => {
                this.allCategories = res.data;
                const category = this.activeCategory || this.allCategories[0];
                this.getQuestions(category);
            });
        },
        getQuestions: function(category) {
            this.activeCategory = category;
            let params = '?age=' + this.ageGroup;
            if (!this.isAdmin) {
                params += '&authId=' + this.$store.getters.author.id;
            }
            axios.get('/questions/' + category.id + params).then(res => {
                this.questions = res.data;
            });            
        },
        filterAge: function(e) {
            this.ageGroup = e.target.value*1;    
            this.getCategories();       
        },
        filterCategories: function(e) {
            this.showCategories = e.target.value;
            this.getQuestions(this.categories[0]);
        }, 
    }    
}
</script>