<template>
    <carousel :items-to-show="1" :wrap-around="true">
      <slide v-for="pic in pictures" :key="pic">                           
        <video v-if="pic.video" controls playsinline :width="pic.width" :height="pic.height" :autoplay="pic.autoplay" :muted="pic.muted" :loop="pic.loop" :alt="pic.alt">
            <source :src="pic.video" type="video/mp4">
            Nettleseren støtter ikke avspilling av video
        </video>                              
        <img v-else :src="pic.img" :alt="pic.alt">
      </slide>
  
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
    props: {
        pictures: Array,
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,  
    },
    data() {
        return {

        }
    },
}


</script>