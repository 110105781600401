<template>
    <div>
        <div class="pt-2 sm:max-w-md flex flex-col gap-y-3 mx-auto">
            <div class="flex flex-wrap sm:flex-nowrap gap-x-4 gap-y-2">                
                <iframe :src="'/game/?uuid=' + testUUID + '&test=true'" class="appFrame"></iframe>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        testUUID() {
            return process.env.VUE_APP_SHOW_IN_APP_UUID;
        }
    }
}

</script>

<style scoped>
.appFrame {
    width: 100%;
    height: 700px;
}

</style>