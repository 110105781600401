<template>
    <div class="">
        <div class="font-semibold mb-2">Pending approval</div>

        <div> 
            <div v-for="(q, i) in questions" :key="i" class="border-t border-gray-300 mt-1 pt-1">                
                <div class="float-left text-sm px-1 bg-gray-200 whitespace-nowrap">{{q.categoryName}}</div>
                <div class="flex justify-between w-full">
                    <div class="cursor-pointer" @click="approve(q)">{{ q.question }}</div>                
                    <div class="text-sm italic whitespace-nowrap">{{ formatDateTime(q.created) }}</div>
                </div>
            </div>
        </div>

    </div>

    <EditQuestion ref="approveQuestionDlg" :approve="true" />
</template>

<script>
import axios from 'axios';
import EditQuestion from '@/components/EditQuestion.vue';
import { formatDateTime } from '@/utils.js';

export default {
    components: {
        EditQuestion
    },
    props: {
        isAdmin: Boolean,
    },
    data() {
        return {
            questions: [],
        }
    },
    created() {
        this.getQuestionsToApprove();
    },
    methods: {
        formatDateTime,
        getQuestionsToApprove: function() {
            let authorUserId = this.$store.getters.author.id;
            axios.get('/questions/approve?userid=' + authorUserId).then((res) => {
                this.questions = res.data;
            });
        },
        approve: function(question) {
            this.$refs.approveQuestionDlg.open(question).then(() => {
                this.getQuestionsToApprove();
            });           
        }
    }
}
</script>