<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">
            <div class="modal-content max-w-lg my-2">

                <div class="pt-4 pb-6 text-center">
                    <div class="font-semibold mb-3">Behandle ordre: {{order.id}}</div>
                    
                    <div v-if="order.shipmentId">
                        <div class="border-gray-400 border-2 overflow-x-auto overflow-y-auto">
                            <pdf :src="pdfLabelSource" :rotate="90" />
                        </div>
                        <button class="btn mt-1 float-right" @click="printLabel()">Print</button>
                    </div>
                    <div v-else>                        
                        <button class="btn" @click="createShipmentLabel()">Lag Quickship fraktlapp</button>
                        eller
                        <div class="mt-4 flex justify-center items-center gap-x-2">
                            <label>
                                Angi kollinummer:
                                <input type="text" :value="order.trackingCode" ref="trackingCode">                            
                            </label>
                            <button class="btn btn-pri" @click="saveManualTrackingCode()">Lagre</button>
                        </div>                        
                    </div>
                    
                    <div class="mt-8 text-left">
                        <div class="">
                            Bytt QR-kort:
                            <span v-for="(boardId, id) in order.boardIds" :key="id" class="underline cursor-pointer mr-2" @click="changeBoard(boardId)">{{ boardId }}</span>
                        </div>

                        <div class="mt-4 mx-auto max-w-sm"> 
                            <div v-for="(checkPoint, i) in checkList" :key="i">                  
                                <input type="checkbox" v-model="checkPoint.checked" :id="'check'+i"> 
                                <label :for="'check'+i" class="ml-2">
                                    {{checkPoint.name}}
                                    <span v-if="checkPoint.qr" >
                                        <span class="font-semibold">#{{order.boardIds}}</span>
                                        <span v-if="order.boardIds.length > 1">
                                            <span class="ml-2 underline cursor-pointer" @click.prevent="printCategories()">Print kategorikort</span>
                                        </span>                                    
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                               

                <div class="modal-footer"> 
                    <button class="btn mr-2" :class="{'btn-disable': !checklistOk}" @click="process()">Ferdigbehandlet</button>
                    <button class="btn" @click="close()">Avbryt</button>
                </div>

                <div class="text-center text-sm">
                    Leveringsbekreftelse blir sendt til: {{order.email}} 
                </div>

                <div v-if="processError" class="mt-3 p-2 bg-red-200">
                    {{processError}} 
                </div>
            </div>
        </div>
    </teleport>
</template> 

<script>
import axios from 'axios';
import pdf from 'vue3-pdf';
import { jsPDF } from 'jspdf';

export default {
    components: {
        pdf,
    },
    computed: {
        checklistOk() {
            let ok = this.order.shipmentId || this.order.trackingCode;
            this.checkList.forEach(checkPoint => {
                if (!checkPoint.checked) {
                    ok = false;
                }
            });
            return ok;
        },
    },
    data() {
        return {
            show: false,
            order: null,
            checkList: [                                 
                { name: 'QR-kort pakket', qr: true },
                { name: 'Regelark pakket' },
                { name: 'Fraktlapp OK' },                         
            ],
            processError: null,
            pdfLabelSource: '',
            resolve: null,
            reject: null,            
        }
    },
    methods: {
        open: function(order) {            
            this.show = true;
            this.order = order;
            this.checkList.forEach(checkPoint => {
                checkPoint.checked = false;
            });
            if (this.order.shipmentId) {
                this.getShipmentLabel();
            }            
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });               
        },
        createShipmentLabel: function() {
            this.processError = null;
            let conf = this.order.shipping === 0;
            if (!conf || confirm('Denne ordren har gratis frakt. Er du sikker på at du vil lage en adresselapp (kostnader påløper)?')) {
                axios.put('/shipment/label/' + this.order.id).then((ret) => {
                    this.order.shipmentId = ret.data.shipmentId;
                    this.getShipmentLabel(); 
                }).catch((err) => {
                    console.log(err);
                    this.processError = 'Det skjedde en feil: ' + err.message;
                });
            }
        },
        getShipmentLabel: function() {
            axios.get('/shipment/label/' + this.order.shipmentId).then((ret) => {
                if (ret.data.labelBase64) {
                    this.pdfLabelSource = 'data:application/pdf;base64,' + ret.data.labelBase64;
                }
            });
        },
        printLabel: function() { 
            const canvas = document.getElementsByTagName('canvas')[0];            
            const winParams = 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=200,screenY=100,width=840,height=500';
            const htmlPop = '<img src="' + canvas.toDataURL() + '">';
            const printWindow = window.open('', 'PDF', winParams);
            printWindow.document.write(htmlPop);       
            setTimeout(() => {
                printWindow.print();
            }, 200);
        },
        saveManualTrackingCode: function() {
            const trackingCode = this.$refs.trackingCode.value.trim();
            axios.put('/order/trackingcode/' + this.order.id + '?code=' + trackingCode).then(() => {
                this.order.trackingCode = trackingCode;
            });            
        },
        printCategories: function() {    
            axios.get('/order/categories?boardIds=' + this.order.boardIds.join()).then((res) => {
                const pdf = new jsPDF();
                const left = 30;
                const top = 30;
                const lineHeight = 8;
                for (let i = 0; i < res.data.length; i++) {
                    const board = res.data[i];
                    pdf.setFontSize(16);
                    pdf.text('Kategorier brett #' + board.id, left, top);
                    pdf.setFontSize(12);
                    let count = 0;
                    for (let t = 0; t < board.categories.length; t++) {
                        const category = board.categories[t];
                        if (category.boardCategory) {
                            count++;
                            pdf.text(category.name, left, top+(count*lineHeight));                            
                        }
                    }   
                    if (i+1 < res.data.length) {
                        pdf.addPage();
                    }
                }
                pdf.save('categories.pdf');
            });
        },
        process: function() {            
            if (this.checklistOk) {
                this.processError = null;
                axios.put('/order/' + this.order.id).then(() => {
                    this.close();
                }).catch((err) => {
                    console.log(err);
                    this.processError = 'Det skjedde en feil: ' + err.message;
                });
            }
        },
        changeBoard: function(boardId) {
            const newBoardId = prompt('Nytt QR-kort (for ' + boardId + ')');
            if (newBoardId) {
                const data = {
                    oldBoard: boardId,
                    newBoard: newBoardId,
                };
                axios.put('/order/board/id', data).then(() => {
                    this.close();
                });
            }
        },
        close: function() {
            this.show = false;
            this.processError = null;
            this.resolve();
        }
    }
}
</script>

