<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">
            <div class="modal-content max-w-xl sm:my-2">
                <div class="pt-4 pb-6 text-center">

                    <div v-html="content"></div>
            
                    <div v-if="edit" class="mt-4">
                        <form enctype="multipart/form-data">
                            <input type="file" :accept="fileAccept" @change="fileUpload($event.target.files)">                     
                        </form>
                        <form v-if="media.id" class="mt-4">
                            <div class="flex items-baseline mb-2">
                                <label class="w-24 text-left">Source</label>
                                <input type="text" class="w-full" v-model="media.source">
                            </div>
                            <div class="flex items-baseline mb-2">
                                <label class="w-24 text-left">Copyright</label>
                                <input type="text" class="w-full" v-model="media.copyright">
                            </div>
                            <div class="flex items-baseline mb-2">
                                <label class="w-24 text-left">Attribution</label>
                                <input type="checkbox" v-model="media.showAttribution"> <span class="ml-2 text-sm">(show copyright text on image)</span>
                            </div>
                            <div class="flex items-baseline">
                                <label class="w-24 text-left">Keywords</label>
                                <input type="text" class="w-full" v-model="media.keywords">
                            </div>
                        </form>
                    </div>

                </div>

                <div class="modal-footer">
                    <button v-if="media.id && edit" class="btn mr-3" @click="saveMediaInfo()">Save</button>
                    <button class="btn" @click="close(false)">Close</button>
                </div>

            </div>
        </div>
    </teleport>    
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            show: false,
            isPicture: false,
            isAudio: false,
            isVideo: false,
            content: '',            
            media: {
                id: 0,
                source: '',
                copyright: '',
                keywords: '',
                showAttribution: false,
            },
            defaultSource: 'Shutterstock',
            resolve: null,
            reject: null,             
        }
    },
    props: {
        edit: Boolean
    },
    computed: {
        fileAccept() {
            if (this.isPicture) {
                return 'image/jpeg, image/png, image/gif';
            }
            if (this.isAudio) {
                return 'audio/mp3';
            }
            if (this.isVideo) {
                return 'video/mp4'; // ???
            }            
            else {
                return '';            
            }
        }
    },
    methods: {
        newPicture: function() {
            this.open('Please use max 640 px image width');
            this.isPicture = true;
        },
        newAudio: function() {
            this.open();            
            this.isAudio = true;
        },
        newVideo: function() {
            this.open('Please use Standard Definition (SD) resolution and short clips to prevent large file size');            
            this.isVideo = true;
        },        
        openPicture: function(pictureId, mediaAttribution) {           
            const now = new Date();
            const imgUrl = process.env.VUE_APP_API + '/question/media/' + pictureId + '?_=' + now.getTime();
            let img = new Image();
            img.src = imgUrl;
            img.onload = () => {
                this.content = '<div>';
                this.content = '<img class="m-auto max-h-64 border border-gray-400" src="' + img.src + '">';
                if (mediaAttribution) {
                    this.content += '<div>' + mediaAttribution + '</div>';
                }
                this.content += 'Dimension: ' + img.width + ' x '  + img.height + '</div>';
            } 
            this.open();           
            this.media.id = pictureId;
            this.isPicture = true;
            this.getMediaInfo();
        },       
        openAudio: function(audioId) {
            const now = new Date();
            const audSrc = process.env.VUE_APP_API + '/question/media/' + audioId + '?_=' + now.getTime();
            this.content = '<audio class="m-auto" controls><source src="' + audSrc + '"></audio>';
            this.open();
            this.media.id = audioId;
            this.isAudio = true;
            this.getMediaInfo();
        },
        openVideo: function(videoId) {
            const now = new Date();
            const vidSrc = process.env.VUE_APP_API + '/question/media/' + videoId + '?_=' + now.getTime();
            this.content = '<video width="320" height="240" class="m-auto" controls><source src="' + vidSrc + '"></video>';
            this.open();
            this.media.id = videoId;
            this.isVideo = true;            
            this.getMediaInfo();
        }, 
        openMedia: function(id, mimeType) {
            //console.log('open media', id, mimeType);
            if (mimeType.startsWith('image')) {
                this.openPicture(id);
            }
            else if (mimeType.startsWith('audio')) {
                this.openAudio(id);
            }
            else if (mimeType.startsWith('video')) {
                this.openVideo(id);
            }                        
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });              
        },
        getMediaInfo: function() {
            axios.get('/media/info/' + this.media.id).then((res) => {
                this.media.source = res.data.source || this.defaultSource;
                this.media.copyright = res.data.copyright;
                this.media.keywords = res.data.keywords;       
                this.media.showAttribution = res.data.showAttribution;                     
            });
        },
        saveMediaInfo: function() {
            if (!this.media.keywords.trim().length) {
                alert('Please provide keywords');
                return;
            }
            axios.post('/media/info', this.media).then(() => {
                this.close(true);
            });
        },
        fileUpload: function(fileList) {    
            let uploadData = new FormData();      
            uploadData.append('media', fileList[0]);            
            axios.post('/upload/' + this.media.id, uploadData).then(res => {  
                this.openMedia(res.data.id, fileList[0].type);
            });
        },     
        open: function(content) {
            if (content) {
                this.content = content;
            }
            this.show = true;
        },
        close: function(edit) {
            this.show = false;
            this.isPicture = false,
            this.isAudio = false,
            this.isVideo = false,
            this.media = {
                id: 0,
                source: '',
                copyright: '',
                keywords: '',
            }
            this.content = '';
            if (this.resolve) {
                this.resolve(edit);
            }
        },
    }
}
</script>