<template>
    <div>

        <div class="flex gap-x-4">
            <div class="text-xl font-semibold">Bestillinger av kategorier</div>
            <button class="btn mb-4" @click="getOrders()">Oppfrisk</button>
        </div>
        
        <div class="font-semibold text-lg mb-2">Initielle bestillinger ({{tempOrders.length}})</div>
        <div v-if="tempOrders.length" class="overflow-x-auto">
            <table>
                <thead>
                    <tr>
                        <th>Ordre ID</th>
                        <th>Ordre tidspunkt</th>
                        <th>Brett</th>
                        <th>Kategori</th>
                        <th>Type</th>
                        <th>Beløp</th>
                        <th>MVA</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, i) in tempOrders" :key="i">
                        <td>{{order.id}}</td>
                        <td>{{formatDateTime(order.orderTime)}}</td>
                        <td>{{order.boardId}}</td>
                        <td>{{order.categoryName}}</td>
                        <td>{{order.addCategory ? 'Legg til' : 'Oppdater'}}</td>
                        <td class="text-right">{{order.amount}}</td>
                        <td class="text-right">{{order.vatAmount}}</td>                        
                        <td @click="checkPayment(order.id)" class="underline cursor-pointer">Sjekk betaling</td>
                        <td @click="deleteOrder(order.id)" class="underline cursor-pointer">Slett</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="font-semibold text-lg mt-6 mb-2">Behandlet ({{orders.length}})</div>
        <div v-if="orders.length" class="overflow-x-auto">
            <table>
                <thead>
                    <tr>
                        <th>Ordre ID</th>
                        <th>Ordre tidspunkt</th>
                        <th>Brett</th>
                        <th>Kategori</th>
                        <th>Type</th>
                        <th>Beløp</th>
                        <th>MVA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, i) in orders" :key="i">
                        <td>{{order.id}}</td>
                        <td>{{formatDateTime(order.orderTime)}}</td>
                        <td>{{order.boardId}}</td>
                        <td>{{order.categoryName}}</td>
                        <td>{{order.addCategory ? 'Lagt til' : 'Oppdatert'}}</td>
                        <td class="text-right">{{order.amount}}</td>
                        <td class="text-right">{{order.vatAmount}}</td> 
                    </tr>
                </tbody>
            </table>
        </div>        

    </div>
</template>

<script>
import axios from 'axios';
import { formatDateTime } from '@/utils.js';

export default {
    data() {
        return  {
            tempOrders: [],
            orders: [],
        }
    },
    created() {
        this.getOrders();
    },        
    methods: {
        formatDateTime,
        getOrders: function() {
            this.$root.showLoading(true);
            axios.get('/orders/category').then(res => {                
                this.tempOrders = res.data.temp_orders;            
                this.orders = res.data.orders.reverse();
                this.$root.showLoading(false);
            });
        },    
        checkPayment: function(orderId) {
            this.$root.showLoading(true);
            axios.get('/board/checkpayment/' + orderId).then(res => {                
                if (res.data.accepted) {
                    this.getOrders();                    
                }
                this.$root.showLoading(false);
            });
        },        
        deleteOrder: function(orderId) {
            if (confirm('Vil du slette denne bestillingen?')) {
                this.$root.showLoading(true);
                axios.delete('/categoryOrder/' + orderId).then(() => {
                    //console.log(res.data);
                    this.getOrders(); 
                    this.$root.showLoading(false);
                });
            }
        },
    }
}
</script>

<style scoped lang="postcss">
    table td, table th {
        @apply px-4 py-1;
    }
    table tr {
        @apply border-b border-gray-300;
    }
</style>