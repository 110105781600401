<template>

    <div class="font-semibold">Question count (approved)</div>
    <div class="mb-2 text-sm">[10-12, 13-15, 16-99]</div>
    <div>
        <div v-if="stats">
            <div v-for="(cat,t) in stats.categories" :key="t" class="mb-2">           
                <div class="font-semibold">{{ cat.name }} ({{ getTotCount(cat.questions) }})</div>        
                <div>
                    <span class="text-sm">{{ getPercent(cat.questions, cat.options) }}</span>              
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            stats: null,     
        }
    },
    props: {
        isAdmin: Boolean,
    },
    methods: { 
        getPercent: function(countObj, optionCount) {        
            const countArray = this.getCountArray(countObj);
            let percent = '';
            let totCount = countArray.reduce((a, b) => a + b, 0);
            let allGroup = countArray[0];
            for (let i = 1; i <= 3; i++) {               
                let groupCount = countArray[i];                                                
                percent += this.percent(groupCount+(allGroup/3), totCount) + '%';
                if (i < 3) {
                    percent += ', ';
                }
            }        
            const optionPercent = this.percent(optionCount, totCount);
            return '[' + percent + '] options: ' + optionPercent + '%';
        },
        getCountArray: function(countObj) {
            const groups = [0, 1, 2, 3];
            let countArray = [];
            for (let i = 0; i < groups.length; i++) {  
                let groupCount = countObj[groups[i]] || 0;  
                countArray[i] = groupCount;
            }
            return countArray;
        },
        percent: function(v, tot) {
            return tot > 0 ? Math.floor(v/tot*100) : 0;
        },
        getTotCount: function(countObj) {
            //console.log(countObj);
            return this.getCountArray(countObj).reduce((a, b) => a + b, 0);
        }
    },
    created() {
        axios.get('/question/stats').then((res) => {
            this.stats = res.data;
        });
    },
}
</script>