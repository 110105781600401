<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal z-50">
            <div class="modal-content sm:max-w-3xl sm:mt-3">

                <div class="flex justify-end -mr-2 -mt-2 mb-0 cursor-pointer" @click="close()">
                    <svg class="h-8 w-8" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>                    
                </div>

                <div class="modal-header text-blue sm:text-2xl">Spørsmål og svar</div>

                <div class="pt-2 pb-4 text-center">                    
                    <div v-for="(faq,i) in faq" :key="i" class="mt-2 pt-2 border-t text-left flex">
                        <div class="mr-2 -mt-2 text-3xl cursor-pointer text-blue" @click="toggleAnswer(i)">
                            <span v-if="isAnswerVisible(i)">&minus;</span>
                            <span v-else>&plus;</span>
                        </div>
                        <div>
                            <div class="text-lg text-gray-800 cursor-pointer hover:text-black" v-html="faq.q" @click="toggleAnswer(i)"></div>
                            <div class="font-semibold text-blue mt-1 transition-all duration-100" v-if="isAnswerVisible(i)" v-html="faq.a"></div>
                        </div>
                    </div>
                </div>                

                <div class="modal-footer flex justify-evenly">
                    <button class="btn py-2 px-6" @click="close()">Lukk</button>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>

export default {

    data() {
        return {
            show: false,
            faq: [
                {
                    q: 'Trenger man tilgang til internett for å spille?',
                    a: 'Ja, for å kunne holde spillet oppdatert hentes spørsmålene direkte fra oss over internett.'
                },           
                {
                    q: 'Hvordan fungerer det at barn og voksne kan spille sammen?',
                    a: 'Hver spiller velger sin alder i appen. Spillet tilpasser da automatisk spørsmålene etter alderen på spilleren. I fellesrundene, der alle får spørsmål samtidig, vil spørsmålet være ulikt dersom det er spillere i ulike aldere med å spiller.'
                },                       
                {
                    q: 'Hvordan kan jeg oppdatere eller kjøpe flere kategorier?',
                    a: 'Dette gjøres enkelt i appen. Nederst på siden der du velger kategorier vil du finne oppdaterte og nye kategorier.'
                },
                {
                    q: 'Må alle spillerne oppdatere kategoriene for å få tilgang til nye spørsmål?',
                    a: 'Nei, oppdateringen skjer kun én gang og alle som spiller får tilgang til evt. nye kategorier og oppdaterte spørsmål.'
                },
                {                                        
                    q: 'Hvor mye koster det å kjøpe flere kategorier?',
                    a: 'Prisen kan variere noe, men de fleste koster kun kr 49,-'
                },
                {
                    q: 'Vil kategorier jeg har kjøpt eller oppdatert bli borte dersom jeg får ny telefon og installerer appen på nytt?',
                    a: 'Nei, du vil beholde alle kjøp som er gjort selv om appen installeres på nytt. Kjøpene kobles direkte til spillets QR-kode og vil gjenskapes dersom appen installeres på nytt.'
                },
                {
                    q: 'Vil de samme spørsmålene dukke opp flere ganger?',
                    a: 'Vi passer på at spørsmålene ikke vises flere ganger. Dersom alle spørsmålene i en kategori er "brukt opp" vil vi gjøre en omstokk av disse.'
                },                           
                {
                    q: 'Hva gjør jeg dersom jeg har mistet kortet med QR-koden?',
                    a: 'Send oss en e-post med koden (5 tall/bokstaver) du mottok da du kjøpte spillet, så sender vi deg QR-koden på nytt.'
                },                                
                {
                    q: 'Hvorfor må vi scanne QR-koden hver gang vi skal spille?',
                    a: 'Dette er for å sikre at alle spillerne kommer inn i samme gruppe på vår server.'
                },                                
                {
                    q: 'Hvordan kommuniserer mobiltelefonene med hverandre?',
                    a: 'Kommunikasjonen skjer via vår server. Det er ingen direkte kommunikasjon mellom telefonene.'
                },                                
                {
                    q: 'Kan man bruke den samme appen dersom man spiller hos andre?',
                    a: 'Ja, start appen og scan QR-koden til det aktuelle spillet. Det er ikke nødvendig å installere appen på nytt selv om spiller Ainou hos venner som også har spillet. Merk at kategoriene følger spillet og ikke appen, slik at det vil kunne være ulike kategorier tilgjengelig avhengig av hvor du spiller.'
                },                                
                {
                    q: 'Dersom jeg oppdaterer eller kjøper flere kategorier, vil de andre spillerne også kunne bruke disse?',
                    a: 'Ja, kategorier som oppdateres eller legges til vil følge QR-koden du scannet før kjøpet. Alle som spiller dette spillet vil få tilgang til de nye spørsmålene.'
                },  
                {
                    q: 'Hvorfor kan jeg ikke se poeng og andre spillere i appen?',
                    a: 'Vi har valgt å ha fokus på spillebrettet og ikke ha mer informasjon enn nødvendig i appen for å unngå at spillerne fokuserer for mye på sine egne telefoner.'
                },                   
                {
                    q: 'Hvorfor kan jeg ikke se spørsmålet i appen når jeg skal svare?',
                    a: 'Vi har valgt å gjøre det slik for å unngå at spillerne kun fokuserer på sine egne mobiltelefoner. Tanken er at det skal være dialog mellom spillerne på samme måte som om spørsmålet ble lest fra et kort.'
                },
                /*{
                    q: 'Hvem står bak Ainou?',
                    a: 'Ainou er utviklet av Hanne Marthinsen og Ole Magnus Habberstad. Hanne har det overordnede ansvaret for spørsmålene og Ole Magnus er ansvarlig for den tekniske delen av spillet.'
                }*/              
            ]
        }
    },
    methods: {
        open: function() {
            this.show = true;
        },
        close: function() {
            this.show = false;
        },
        isAnswerVisible: function(idx) {            
            return this.faq[idx].showAnswer === true;
        },
        toggleAnswer: function(idx) {            
            this.faq[idx].showAnswer = !this.isAnswerVisible(idx);
        }        
    }
}
</script>
