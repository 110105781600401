<template>
    <div> 
        <div class="text-sm mb-2">Count: {{ media.length }}</div>
        <table>          
            <tr v-for="(m, i) in media" :key="i" class="border-t">
                <td @click="openMedia(m)" class="cursor-pointer mr-3">{{ getIcon(m.mimeType) }}</td>
                <td>{{ m.keywords }}</td>
                <td>{{ m.source }}</td>
                <td>&copy;{{ m.copyright }}</td>
                <td>{{ getKb(m.size) }} kb</td>
            </tr>            
        </table>
    </div>

    <MediaModal ref="mediaModal" :edit="true" /> 
</template>

<script>
import MediaModal from '@/components/MediaModal.vue';

export default {
    props: {
        media: Array,
    },    
    components: {
        MediaModal
    },
    emits: ['edit'],
    methods: {
        getKb: function(size) {
            return parseInt(size/1000);
        },
        isPicture: function(mimeType) {
            return mimeType.startsWith('image');
        },
        isAudio: function(mimeType) {
            return mimeType.startsWith('audio');
        },       
        isVideo: function(mimeType) {
            return mimeType.startsWith('video');
        },          
        getIcon: function(mimeType) {            
            if (this.isPicture(mimeType)) {
                return '🖼️';
            }
            if (this.isAudio(mimeType)) {
                return '🔊';
            }
            if (this.isVideo(mimeType)) {
                return '🎞️';
            }            
        },
        openMedia: function(media) {   
            this.$refs.mediaModal.openMedia(media.id, media.mimeType).then((edit) => {
                if (edit) {
                    this.$emit('edit');
                }
            });
        }
    }
}
</script>

<style scoped>
    table td {
        @apply px-2 py-1;
    }
</style>