<template>
    <div class="border-b border-gray-500 py-1 font-semibold flex justify-between">
        <div v-if="isAdmin" class="w-1/2 sm:w-max text-right">
            <form @submit.prevent="search()">
                <div class="flex flex-wrap gap-1 sm:flex-nowrap">
                    <input type="search" v-model="searchString" class="w-full" placeholder="Search">
                    <select v-model="category">
                        <option value="0">&lt;All&gt;</option>
                        <option v-for="(cat, c) in categories" :key="c" :value="cat.id">
                            {{cat.name}}
                        </option>
                    </select>
                    <button type="submit" class="btn">Search</button>
                </div>
            </form>
        </div>
    </div>    
    
    <div class="mt-2">        
        <Search ref="search" v-on:clear="clearSearch" />        
        <div v-if="!searchActive">
            <router-view :key="$route.fullPath" :isAdmin="isAdmin" />
        </div>
    </div>    

</template>

<script>
import axios from 'axios';
import Search from '@/pages/Admin/Questions/Search.vue';

export default {
    data() {
        return {
            searchString: '',
            searchActive: false,
            category: 0,
            categories: [],
        }
    },
    props: {
        isAdmin: Boolean 
    },
    components: {              
        Search,  
    },
    created() {
        this.getCategories();
    },
    methods: {
        search: function() {         
            this.searchActive = true;
            this.$refs.search.doSearch(this.searchString, this.category);     
        },
        clearSearch: function() {
            this.searchActive = false;
        },
        getCategories: function() {
            axios.get('/question/categories').then(res => {
                this.categories = res.data;
            });            
        },
    }
}
</script>