<template>
    <div>         
        <QuestionList :questions="expireQuestions" :showCategory="true" v-on:edit="getExpireQuestions" />
    </div>
</template>

<script>
import axios from 'axios';
import QuestionList from '@/components/QuestionList.vue';

export default {
    components: {
        QuestionList
    },
    data() {
        return {
            expireQuestions: {}
        }
    },
    created() {
        this.getExpireQuestions();
    },
    methods: {
        getExpireQuestions: function() {
            axios.get('/questions/expire').then(res => {
                this.expireQuestions.questions = res.data;
                this.expireQuestions.totCount = res.data.length;
            });
        },        
    }
}
</script>