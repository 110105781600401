<template>    
    <teleport to="#modals"> 
        <div v-if="visible" class="fixed top-0 w-full h-full z-30 bg-black bg-opacity-30">    
            <div class="flex items-center justify-center h-full">
            <div class="bg-gray-200 bg-opacity-100 border-gray-400 rounded-lg w-20 h-20">
                <div class="animate-spin p-6">(</div>
            </div>
            </div>
        </div>     
    </teleport>       
</template>

<script>
export default {
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        show: function(b) {
            this.visible = b;
        },
    }
}
</script>