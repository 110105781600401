<template>
    <teleport to="#modals"> 
        <div v-if="show" class="modal">

            <div class="modal-content max-w-md sm:my-2 relative">
                
                <div class="font-semibold text-lg mb-2">Edit board | #{{ board.id }} | {{ board.code }} | <fa-icon v-if="board.isTravel" icon="plane-departure" /></div>

                <div class="absolute right-0 top-0 mr-4 mt-4"> 
                    <img :src="getQR()">                    
                </div>                
                
                <input type="checkbox" id="active" v-model="board.active">
                <label for="active" class="ml-1">Active</label>

                <div v-if="board.purchaseDate" class="mb-2">
                    Purchase date: {{ formatDate(board.purchaseDate) }}
                </div>
                
                <div>
                    <label>Owner</label><br>
                    <input type="text" v-model="board.owner" class="w-2/3">
                </div>

                <div v-if="board.isTravel && board.connectedCode" class="mb-3">
                    Connected to: {{ board.connectedCode }}
                </div>

                <div v-if="categories.length"> 
                    <div class="font-semibold mb-2">Categories</div>
                    <div v-for="(cat,i) in categories" :key="i" class="flex justify-between">
                        <div>
                            <fa-icon v-if="cat.price === 0 && !cat.isTravel" icon="gift" />                        
                            <fa-icon v-else-if="cat.isConnected" icon="link" />
                            <input v-else type="checkbox" :id="'cat'+i" v-model="checkedCategories" :value="cat">
                            <label :for="'cat'+i" class="ml-2">
                                <fa-icon v-if="cat.isTravel" icon="plane-departure" /> {{ cat.name }} <span class="text-sm">(ver. {{ formatDate(cat.versionDate) }})</span>
                            </label>
                        </div>
                        <div v-if="showGetLatestVersion(cat)" class="underline cursor-pointer text-sm" @click="setLatestVersion(cat)">Upgrade</div>
                    </div>
                </div>

                <div class="modal-footer flex justify-center space-x-3">
                    <button class="btn" @click="save()">Save</button>
                    <button class="btn" @click="close()">Cancel</button>
                </div>
            </div>
        </div>
    </teleport>       
</template>

<script>
import axios from 'axios';
import { formatDate } from '@/utils.js';

export default {    
    computed: {

    },
    data() {
        return {
            show: false,
            board: null,            
            resolve: null,
            reject: null,       
            categories: [],
            checkedCategories: [],
        }
    },
    methods: {
        formatDate,
        open: function(board) {
            this.board = board;            
            this.getCategories();  
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });                         
        },
        save: function() {          
            this.board.boardCategories = this.checkedCategories;
            axios.post('/board/edit', this.board).then(() => {
                this.close();
            });            
        },
        close: function() {
            this.show = false;
            this.resolve();
        },
        getCategories: function() {
            axios.get('/board/categories?boardId=' + this.board.id).then((res) => {
                this.categories = res.data.filter(c => !c.isTravel || this.board.isTravel);
                this.checkedCategories = [];
                this.board.boardCategories = [];
                for (let i = 0; i < this.categories.length; i++) {
                    let cat = this.categories[i];
                    if (cat.boardCategory) {
                        this.board.boardCategories.push(cat.id);
                        const isFree = cat.price === 0 && !cat.isTravel;
                        if (!isFree && !cat.isConnected) {
                            this.checkedCategories.push(cat);
                        }
                    }
                }
                this.show = true;
            });
        },
        showGetLatestVersion: function(cat) {
            return this.checkedCategories.includes(cat) && cat.newVersionQuestionDiff;
        },
        getQR: function() {  // http://goqr.me/api/
            const scanUrl = window.location.origin + process.env.VUE_APP_PUBLIC_PATH + 'scan?uuid=' + this.board.uuid;        
            return 'https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=' + scanUrl;
        },      
        setLatestVersion: function(cat) {
            axios.put('/board/category/update?boardId=' + this.board.id + '&catId=' + cat.id).then(() => {
                this.getCategories();
            });            
        }
    }
}
</script>

<style scoped>

input, textarea, select {
    @apply mb-3;
}

</style>