<template>
    <div class="flex flex-col justify-center w-full items-center">         

        <div class="mb-2 mx-12 text-white font-semibold text-center">
            <div v-if="canAnswer">
                <span v-if="answerGiven">
                    Svaret er {{ correct ? 'korrekt 🥳' : 'feil 😔' }}
                </span>
                <span v-else>Trykk og hold på bildet for å svare</span>
            </div>
            <div v-else-if="playerCount > 1">
                Spilleren svarer ved å trykke og holde på bildet
            </div>
        </div>
  
        <div>
            <progress v-if="canAnswer" :value="holdValue" :max="holdSec*100" class="w-full h-2"></progress>
            <img 
                :src="imgUrl"                               
                id="imgMap" 
                usemap="#image-map" 
                class="mx-auto max-h-64_ rounded-xl bg-white"
                @load="calcArea()" 
                @contextmenu.prevent="handleImgContextMenu"                
            >    
            <map v-if="polyArea" name="image-map" v-html="polyArea" @contextmenu.prevent="handleImgContextMenu" />                            
        </div>
        
    </div>
</template>

<script>
import { loadJQuery } from '@/utils';

export default {
    props: {
        imgUrl: String,
        player: Boolean,
        playerCount: Number,
        area: String,
        hideArea: Boolean,
        travel: Boolean,
        sound: Object,
    },
    emits: [
        'answer'
    ],
    computed: {
        canAnswer() {
            return (this.player || this.playerCount === 1) && this.hideArea;
        }
    },
    data() {
        return {
            polyArea: null,
            highlight: {
                fillColor: 'ffffff',
                fillOpacity: .4,
                strokeColor: '008000',
                strokeOpacity: 1,
                strokeWidth: 2,
            },
            answerGiven: false,
            correct: false,
            holdBarInt: null,
            holdProgressInt: null,
            holdValue: 0,
            holdSec: 1.2,            
        }
    },       
    methods: {
        calcArea: function() {  // transform poly coords to match the current image size
            let img = document.getElementById('imgMap'); 
            const dispW = img.clientWidth;
            const dispH = img.clientHeight
            const orgW = this.getAreaParameter('w');
            const orgH = this.getAreaParameter('h');
            const xFactor = dispW/orgW;
            const yFactor = dispH/orgH;
            const coords = this.getAreaParameter('coords');           
            const arCoords = coords.split(',');
            const transformedCoords = [];
            for (let i = 0; i < arCoords.length; i++) {
                const isX = i % 2 === 0;
                const factor = isX ? xFactor : yFactor;
                const newCoord = Math.round(arCoords[i]*factor);
                transformedCoords.push(newCoord);
            }            
            this.polyArea = this.replaceAreaParameter('coords', transformedCoords.join(','));            
            this.$nextTick(() => {  // wait for image map to update/render
                loadJQuery().then(() => {
                    this.setupHighlight();
                });
            });
        },
        getAreaParameter: function(param) {
            let value = '';
            const i = this.area.indexOf(param + '=');
            if (i > -1) {
                const temp = this.area.substring(i + param.length + 1);
                value = temp.split(' ')[0];
            }
            return value.replaceAll('"', '').replaceAll('>', '');
        },
        replaceAreaParameter: function(param, newValue) {
            const orgValue = this.getAreaParameter(param);
            return this.area.replace(orgValue, newValue);
        },
        setupHighlight: function() {
            // eslint-disable-next-line      
            $('#imgMap').maphilight({
                fill: false,
                stroke: false,                
                alwaysOn: false,
            });             
            this.setupHoldEvents();
        },
        setupHoldEvents: function() {
            /* eslint-disable */ 
            if (this.canAnswer) {                              
                $('#imgMap').off('touchstart touchmove touchend');
                $('#imgMap').on('touchstart', () => {
                    this.startHoldBar(false);
                });          
                $('#imgMap').on('touchmove touchend', () => {
                    this.cancelHoldBar();
                });                       
                                
                const area = $('area')[0];
                if (area) {
                    $(area).off('touchstart touchmove touchend');
                    $(area).on('touchstart', () => {
                        this.startHoldBar(true);
                    });            
                    $(area).on('touchmove touchend', () => {
                        this.cancelHoldBar();
                    });        
                }               
            } 
            /* eslint-enable */    
        },
        startHoldBar: function(correct) {
            if (this.answerGiven) {
                return;
            }
            this.holdValue = 0;
            const intMs = 50;
            const step = this.holdSec*100/((this.holdSec*1000/intMs));
            this.holdProgressInt = setInterval(() => {
                this.holdValue += step;
            }, intMs);
            this.holdBarInt = setTimeout(() => {
                this.answer(correct);
                clearInterval(this.holdProgressInt); 
            }, this.holdSec*1000+intMs*2);
        },
        cancelHoldBar: function() {        
            this.holdValue = 0;  
            clearInterval(this.holdProgressInt); 
            clearTimeout(this.holdBarInt);
        },
        updateHighlight: function() {
            // eslint-disable-next-line 
            $('#imgMap').maphilight({ 
                alwaysOn: !this.hideArea || this.answerGiven,
                fill: !this.hideArea || this.answerGiven,
                stroke: !this.hideArea || this.answerGiven,
                fillColor: this.highlight.fillColor,
                fillOpacity: this.highlight.fillOpacity,
                strokeColor: this.highlight.strokeColor,
                strokeOpacity: this.highlight.strokeOpacity,                
                strokeWidth: this.highlight.strokeWidth,
            }); 
            this.setupHoldEvents();
        },
        answer: function(correct) {
            if (this.answerGiven) {
                return;
            }

            this.correct = correct;
            this.answerGiven = true;
            this.updateHighlight();            
            if (this.travel) {
                this.$emit('answer', correct);
            }
            else {
                const sound = correct ? 'correctAnswer' : 'lotteryLoose'; 
                this.sound?.play(sound);    
            }                        
        },
        handleImgContextMenu: function(e) {
            e.preventDefault();
        }
    },
    watch: {
        hideArea() {           
           this.updateHighlight();
        }
    }
}

</script>

<style scoped>

    #imgMap, area {
        -webkit-tap-highlight-color: transparent;
        user-select: none !important;
        -webkit-user-drag: none;
    }

</style>