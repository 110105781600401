function emptyGame() {
    const game = {
        id: null,
        score: 0,
        commonRound: []
    }
    return game;
}


export default {
  
    state() {
        return { 
            clientId: null,
            ageGroup: null,
            game: emptyGame()
        }
    },
    mutations: {
        initializeStore(state) {            
            const localGame = localStorage.getItem('game');
            if (localGame) {
                state.game = JSON.parse(localGame);                        
            }
            else {
                this.commit('clearGame');
            }
            
            const clientId = localStorage.getItem('client_id');
            if (clientId) {
                state.clientId = clientId;
            }
            else {
                console.log('create new client id');
                state.clientId = Math.floor(Math.random()*1000000000);
                localStorage.setItem('client_id', state.clientId);
            }

            const ageGroup = localStorage.getItem('age_group');
            if (ageGroup) {
                state.ageGroup = ageGroup;
            }            

        },        
        setGameId(state, gameId) {
            state.game.id = gameId; 
            this.commit('saveGame'); 
        },
        addCommonRoundQuestion(state, commonRoundQuestion) {
            if (commonRoundQuestion.correct) {
                state.game.score += 1;
            }
            if (!state.game.commonRound) {
                state.game.commonRound = [];  // initialize if not exists
            }
            state.game.commonRound.push(commonRoundQuestion);  // store common question (and answer)
            this.commit('saveGame');
        },
        saveGame(state) {
            localStorage.setItem('game', JSON.stringify(state.game));
        },      
        clearGame(state) {
            state.game = emptyGame();      
            this.commit('saveGame');      
        },
        setAgeGroup(state, group) {
            state.ageGroup = group;
            localStorage.setItem('age_group', group);
        },
    },
    getters: {
        clientId: state => state.clientId,
        ageGroup: state => state.ageGroup,
        game: state => state.game,
    }
}
