<template>
    <div> 

        <div class="flex gap-x-2 items-center mb-2">
            <div class="typeSelect" :class="{'selected': !showTravelCards}" @click="showCardType(false)">Board cards</div>
            <div class="typeSelect" :class="{'selected': showTravelCards}" @click="showCardType(true)">Travel cards</div>
        </div>

        <div v-for="(card,i) in cards" :key="i" class="border-b border-gray-200 py-2">            
            <div class="flex gap-x-2">
                <span @click="editCard(card)" class="cursor-pointer text-blue-900 font-semibold">[Edit]</span> 
                <span :class="{'line-through': !card.active}" class="whitespace-pre-wrap">{{ card.text }}</span>
                <span>({{ percent(card.weight) }}%)</span>
            </div>
        </div>

    </div>

    <teleport to="#modals"> 
        <div v-if="edit" class="modal">
            <div class="modal-content max-w-xl sm:my-2">
                <h3>Edit Card</h3>
                <form @submit.prevent="updateCard">
                    <div class="mt-2"> 
                        <textarea v-model="edit.text" class="w-full h-52 resize-none" required></textarea>
                        <div class="flex justify-between gap-x-4 items-center">
                            <label>Weight <input type="number" min="1" max="9" step="1" v-model="edit.weight" class="w-12" required></label>
                            <label><input type="checkbox" v-model="edit.active"> Active</label>
                        </div>
                    </div>
                    <div class="mt-6 flex gap-x-2 justify-center">
                        <button class="btn" type="submit">Save</button>
                        <button class="btn" type="button" @click="closeEdit()">Cancel</button>
                    </div>
                </form>
            </div>        
        </div>
    </teleport>    

</template>

<script>
import axios from 'axios';

export default {
    computed: {
        cards() {
            return this.allCards.filter(c => c.travel === this.showTravelCards);
        }       
    },
    data() {
        return {
            showTravelCards: false,
            allCards: [],
            edit: null,
        }
    },
    created() {
        this.getCards();
    },
    methods: {
        percent: function(weight) {
            let totWeight = 0;
            this.cards.forEach((card) => {
                totWeight += card.weight;
            });
            return Math.round((weight/totWeight)*100);
        },
        getCards: function() {
            axios.get('/cards').then((res) => {
                this.allCards = res.data;
            });
        },
        editCard: function(card) {
            this.edit = {...card};  // clone
        },
        updateCard: function() {
            axios.put('/card', this.edit).then(() => {
                this.closeEdit();
                this.getCards();
            });
        },
        closeEdit: function() {
            this.edit = null;
        },
        showCardType: function(showTravel) {
            this.showTravelCards = showTravel;
        }
    }
}
</script>

<style scoped>

.typeSelect {
    @apply px-3 cursor-pointer rounded-lg border border-white;
}
.typeSelect:hover {
    @apply border-gray-200;
}

.typeSelect.selected {
    @apply border-gray-400;
}

</style>