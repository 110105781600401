<template>
    <div> 
        <input type="text" ref="wordInput" @keydown.enter="scramble" placeholder="word">
        <button class="btn ml-1" @click="scramble">Scramble</button>

        <div class="flex mt-10 justify-center">
            <div v-for="(letter,i) in letters" :key="i" class="border-2 border-black rounded text-4xl py-1 font-semibold w-11 text-center mr-1">
                {{letter}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            letters: [],
        }
    },
    methods: {
        scramble: function() {
            this.letters = [];
            let word = this.$refs.wordInput.value.toUpperCase();                        
            const usedIdx = [];
            for (let i = 0; i < word.length; i++) {
                let r = -1;
                do {
                    r = Math.floor(Math.random() * word.length);
                } while (usedIdx.includes(r));
                this.letters.push(word.charAt(r));
                usedIdx.push(r);                
            }
        }
    },
}
</script>

<style scoped>

</style>