<template>
    <div v-if="showSearchResult">
        <div class="font-semibold mb-2">
            Search result <span class="underline ml-2 font-normal cursor-pointer" @click="clearSearch">clear</span>
        </div>
        <div>
            <div v-if="this.searchResult.totCount === 0">
                No hits!
            </div>
            <div v-else>
                <QuestionList :questions="searchResult" v-on:edit="reloadSearch()" :showCategory="true" />            
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import QuestionList from '@/components/QuestionList.vue';

export default {
    components: {
        QuestionList
    },    
    computed: {
        showSearchResult() {
            return this.searchResult.totCount !== undefined;
        }
    },
    emits: [
        'clear'
    ],
    data() {
        return {
            s: '',
            c: '',
            searchResult: {},
        }
    },
    methods: {
        doSearch: function(s, c) {
            this.s = s;
            this.c = c;
            if (s && s.length > 1) {
                axios.get('/question/search?s=' + s + '&c=' + c).then(res => {                         
                    this.searchResult.questions = res.data;
                    this.searchResult.totCount = res.data.length;
                });
            }
        },
        reloadSearch: function() {
            this.doSearch(this.s, this.c);
        },
        clearSearch: function() {
            this.searchResult = {};
            this.$emit('clear');
        },
    }
}
</script>