<template>
    <div>     
        <div class="flex justify-between flex-wrap mb-2">   
            <div class="flex flex-wrap gap-x-2">
                <div>Std: {{ stdBoardCount }} |</div>
                <div>Travel: {{ travelBoardCount }} |</div>
                <!--div>Stock: {{ stockCount }} |</div-->
                <div>Tot. questions: {{totQuestionCount}}</div>
            </div>
            <div class="flex items-center gap-x-3">
                <label><input type="checkbox" v-model="showActiveOnly"> Active</label>
                <input type="search" placeholder="search" v-model="searchStr">
            </div>
        </div>
        <div class="flex justify-between mb-3">
            <button @click="getBoards" class="btn">Refresh</button>
            <!--button class="btn" @click="newBoards()">New boards...</button-->
        </div>

        <div class="overflow-x-auto mb-4">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Code</th>
                        <th></th>
                        <th>Questions</th>
                        <th>Games</th>
                        <th>Resets</th>                        
                        <th>Last game</th>
                        <th>Purchased</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(board, i) in filterBoards" :key="i" :class="{'text-gray-400': !board.active}">
                        <td><fa-icon v-if="board.isTravel" icon="plane-departure" /></td>
                        <td class="text-right">{{board.id}}</td>
                        <td><span @click="editBoard(board)" class="font-mono underline cursor-pointer">{{board.code}}</span></td>
                        <td><span v-if="board.connectedCode" class="font-mono">{{ board.connectedCode }}</span></td>
                        <td class="text-right">{{board.questionCount}}</td>
                        <td class="text-right">{{board.gameCount}}</td>
                        <td class="text-right">{{board.categoryReset}}</td>                        
                        <td class="whitespace-nowrap">{{formatDateTime(board.gameCreated)}}</td>
                        <td class="whitespace-nowrap">{{formatDate(board.purchaseDate)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="ml-10 mt-6" v-if="boards.length === 0">
            <span class="animate-spin inline-block">|</span>
        </div>
    </div>

    <BoardDlg ref="modal" />
</template>

<script>
import axios from 'axios';
import BoardDlg from '@/components/BoardDlg.vue';
import {formatDate, formatDateTime} from '@/utils.js';

export default {
    components: {
        BoardDlg
    },
    props: {
        isAdmin: Boolean 
    },    
    computed: {
        filterBoards() {
            let filterBoards = [];
            for (let i = 0; i < this.boards.length; i++) {
                const board = this.boards[i];            
                const owner = board.owner ? board.owner.toLowerCase() : '';                
                const code = board.code.toLowerCase();
                const search = this.searchStr.toLowerCase();
                const visible = this.showActiveOnly ? board.active : true;
                if (board.id > 1 && visible && (owner.includes(search) || code.includes(search) || board.id == search)) {
                    filterBoards.push(board);
                }
            }
            return filterBoards;
        },
        /*stockCount() {
            let count = 0;
            for (let i = 0; i < this.boards.length; i++) {
                if (!this.boards[i].active) {
                    count++;
                }
            }
            return count;
        },*/
        totQuestionCount() {
            let count = 0;
            for (let i = 0; i < this.boards.length; i++) {
                count += this.boards[i].questionCount;                
            }
            return count;
        },
        stdBoardCount() {
            return this.boards.filter(b => b.id > 1000 && !b.isTravel).length;
        },
        travelBoardCount() {
            return this.boards.filter(b => b.id > 5000 && b.isTravel).length;
        },
    },
    data() {
        return  {
            boards: [],
            searchStr: '',
            showActiveOnly: true,
        }
    },
    created() {
        this.getBoards();
    },
    methods: {
        formatDate, formatDateTime,
        getBoards: function() {
            this.boards = [];
            axios.get('/boards').then((res) => {                             
                this.boards = res.data.boards;
            });
        },
        newBoards: function() {
            let count = prompt('Number of boards to add');
            if (count && !isNaN(count) && count > 0) {
                if (confirm('Are you sure you want to add ' + count + ' boards?')) {
                    axios.post('/board/new/' + count).then((res) => {
                        this.getBoards();
                        alert(res.data.created + ' boards added');                        
                    });
                }
            }
        },
        editBoard: function(board) {
            this.$refs.modal.open(board).then(() => {
                this.getBoards();
            });
        },
    } 
}
</script>

<style scoped>
    table th, table td  {
        @apply px-2;
    }
    .info span {
        @apply whitespace-nowrap;
    }

</style>