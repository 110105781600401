<template>
    <div class="pb-6">
        <Navbar v-on:showCart="showCart" />

        <div class="pt-16 sm:pt-32 px-4 sm:px-10">

            <h1 class="text-2xl sm:text-3xl sm:mt-0 mt-6">
                Et unikt kunnskapsspill
            </h1>
            <!--div class="absolute top-20 sm:top-32 right-0 sm:right-4 rotate-12 transform bg-blue text-white rounded text-sm sm:text-lg px-4 py-1">
                Kun på nett!
            </div--> 
            <div class="flex flex-col gap-y-4">
                <div>
                    <div class="mt-1 text-lg sm:text-xl"> 
                        Ainou er et dynamisk kunnskapsspill for hele familien som kombinerer fysisk spill og app.
                        Du velger selv hvilke kategorier du vil spille med og spørsmålene tilpasses alder automatisk!   
                        Flere enn 10.000 spørsmål er tilgjengelig. 
                        <span class="whitespace-nowrap">Utviklet i Norge <img src="/images/norwegian.png" class="inline mb-1" alt="norge"></span>
                    </div> 
                    <div class="mt-5 font-semibold text-xl text-gray-500">Spillet finnes i to varianter</div>
                </div>

                <div class="flex flex-wrap gap-x-8 gap-y-6">
                    <div class="homePageProduct">
                        <div class="heading">Ainou</div>
                        <div class="image" @click="gotoProduct('board')">
                            <img src="/images/brettspill/box-frontside.png">
                        </div>
                        <div class="description">
                            Stort brettspill for inntil 6 spillere
                        </div>
                        <div class="buy">
                            <div class="price-stock">
                                <div class="price">
                                    {{ board.price }},-
                                </div>
                                <div class="stock">
                                    {{ getStockText(board.count) }} stk. på lager
                                </div> 
                            </div>
                            <div>
                                <button class="buyProductBtn" @click="buyBoardGame()">Kjøp</button>
                            </div>
                        </div>
                    </div>

                    <div class="homePageProduct">
                        <div class="heading">Ainou Mini</div>
                        <div class="image" @click="gotoProduct('mini')">
                            <img src="/images/mini/spinner-box.png">
                        </div>
                        <div class="description">
                            Kompakt versjon med spinner!
                        </div>
                        <div class="buy">
                            <div class="price-stock">
                                <div class="price">
                                    {{ travel.price }},-
                                </div>
                                <div class="stock">
                                    {{ getStockText(travel.count) }} stk. på lager
                                </div> 
                            </div>
                            <div>
                                <button class="buyProductBtn" @click="addTravelGameToCart()">Kjøp</button>
                            </div>
                        </div>                        
                    </div>                
                </div>

            </div>
        </div>  

    </div>
    <Footer />

    <BuyGame ref="buyBoardGameDlg" :testMode="ainou.testMode" :categoriesIncluded="ainou.board.categoryCount" :allCategories="boardCategories" :price="board.price" :shipping="board.shipping" :shippingTravel="travel.shipping" :maxPayTime="maxPayTime" :name="ainou.board.name" v-on:close="getProductInfo()" />

</template>

<script>
import axios from 'axios';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import BuyGame from '@/components/BuyGame.vue';
import { getStockText } from '@/utils.js';

export default {
    components: {
        Navbar,
        Footer,
        BuyGame,
    },
    computed: {       
        boardCategories() {
            return this.categories.filter(c => !c.travel);
        },
        travelCategories() {
            return this.categories.filter(c => c.travel && c.active);
        },
    },  
    data() {
        return {
            board: {},
            travel: {},
            maxPayTime: 0,     // from server 
            categories: [],         
        }
    },
    created() {
        this.getProductInfo();
        this.getCategories();  
    },
    methods: {
        getStockText,
        getProductInfo: function() {
            axios.get('/products').then(res => {
                this.board = res.data.board;
                this.travel = res.data.travel;
                this.maxPayTime = res.data.releaseTime;                
            });
        },        
        getCategories: function() {
            axios.get('/question/categories').then((res) => {
                this.categories = res.data;
                document.dispatchEvent(new Event('render-trigger'));
            });        
        },        
        buyBoardGame: function() {
            this.$refs.buyBoardGameDlg.open(true);
        },                
        addTravelGameToCart: function() {
            const now = new Date();
            const product = {
                name: this.ainou.travel.name,
                price: this.travel.price,
                travelGame: true,
                time: now.getTime(),
            };
            this.$store.commit('addToCart', product);   
            this.showCart();
        },     
        showCart: function() {
            this.$refs.buyBoardGameDlg.open(false);
        },         
        gotoProduct: function(name) {
            this.$router.push({name: name});
        }                
    }
}

</script>

<style scoped>

.typeHeading {
    @apply tracking-wider text-2xl text-white font-semibold py-2 pl-6 from-blue-300 sm:to-white to-blue-100 bg-gradient-to-r;
}

.typeContent {
    @apply mt-4 flex gap-x-5 gap-y-2 flex-wrap sm:flex-nowrap;
}

.homePageProduct {
    @apply w-full flex flex-col sm:w-96 border-2 hover:border-gray-300 rounded px-3 sm:px-5 py-2 sm:py-3 sm:shadow-lg hover:bg-gray-50;
}
.homePageProduct .heading {
    @apply text-2xl tracking-wide text-gray-500 font-semibold mb-2;
}
.homePageProduct .image {
    @apply cursor-pointer sm:px-6 py-2 flex-grow;
}
.homePageProduct .image img {
    @apply max-h-56 mx-auto;
}
.homePageProduct .description {
    @apply font-semibold mt-1;
}
.homePageProduct .buy {
    @apply flex items-end justify-between;
}
.homePageProduct .price-stock {
    @apply flex items-center gap-x-3 mt-3;
}
.homePageProduct .price {
    @apply text-2xl text-red-600 font-semibold;
}
.homePageProduct .stock {
    @apply text-sm mt-1;
}
.homePageProduct .buyProductBtn {
    @apply cursor-pointer px-4 py-1 border border-green-800 rounded bg-green-600 hover:bg-green-700 text-white tracking-wide;
}

</style>