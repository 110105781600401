<template>

    <div v-if="isNaN(idx) || idx < 0 || idx >= uuid.length" class="p-4">
        <div>WebSocket simulator</div>

        <div>
            Count: {{ webSockets.length }}
        </div>

        <button @click="openWebSocket()" class="btn">open</button>
    </div>
    <div v-else class="m-4"> 
        <button v-if="!started" class="btn mr-2" @click="start()">Start</button>
        <button v-if="started" class="btn" @click="stop()">Stopp</button>
    </div>
    <div v-if="started" class="flex">         
        <div v-for="(id,i) in uuid[idx]" :key="i" class="border-t-2 border-r-2 border-b-2 border-gray-400">        
            <div class="text-center">Spill {{i+1}}</div>
            <iframe :src="gameUrl(id)" width="370" height="650" />
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {            
            started: false,
            idx: 0,
            uuid: [
                [  // dev/localhost ?id=0
                    'fcfc4d31-421e-4d9b-86fb-d3bdb61f4752',
                    '7c7e7f7a-5519-11eb-ae93-0242ac130002',
                    '865b53d9-3eb9-46d5-9e44-53d1ad0653f2',
                    'b440f960-8e2e-4f46-9290-afba565407cb',
                    '7ced1a6b-7d68-401e-b356-28cfda0272fe',
                ],
                [  // Ole 1 ?id=1
                    '0ec0e4eb-cabf-4853-b59c-87808dd6c089',
                    'c236e030-9f37-4d20-a5b8-867407e8c5c8',
                    '70c5f433-9964-479a-bea9-c39f2756ab7d',
                    'd7c0c8be-4fa9-45a7-86e3-88676e18667b',
                    '9e0888c9-8ef6-45be-a40c-a7178a622ece',
                ],
                [  // Ole 2 ?id=2
                    'f497bd31-cee8-477b-a788-aa588ae2eb32',
                    '4787bd25-8e49-4f27-8f35-4f9cc1184f51',
                    '0228a7ee-81a0-40b0-beb2-65784db5dcc8',
                    '6981628c-0593-42bf-8c5a-7ba1710fda56',
                    'a738ff5f-d321-4770-aec3-c5b6cd938f4a',
                ], 
                [  // Hanne ?id=3
                    '166acc45-eed0-470d-88c0-7bafaf251d48',
                    'b1fd4adc-c9de-4b6f-b648-bb6800e6798e',
                    '023e590d-af20-446b-bf77-621dc5a2027d',
                    '249d8c52-e398-4ed0-ba65-02f9accec02a',
                    'bf78d090-d8e4-4f4a-80cf-f8b617bf3aca',
                ],
                [  // Ivar ?id=4
                    'd9c79104-0f2b-4d0e-8d2b-54b4ac26251e',
                    'd9922b22-e5cd-42b4-950f-2b7c82a1f965',
                    '63caf383-e158-4922-b5b9-35617eea5cb0',
                    '05f4f780-a4f2-4f88-bc3e-faa71a23359b',
                    '6a85e773-d53d-4429-923d-c70645850355',
                ]                
            ],
            webSockets: [],
            //wsCount: 0,
        }
    },
    created() {
        this.idx = window.location.search.split('=')[1]*1;
    },    
    methods: {
        start: function() {
            this.started = true;
        },
        stop: function() {
            this.started = false;
        },
        gameUrl(uuid) {
            return 'game?uuid=' + uuid + '&sim=true';
        },
        openWebSocket: function() {
            const count = this.webSockets.length;
            const uuid = this.uuid[0][count];
            console.log('open', uuid);
            const ws = this.createWebSocket(1, uuid);
            this.webSockets.push(ws);
        },
        createWebSocket: function(clientId, uuid) {
            const wsConn = new WebSocket(process.env.VUE_APP_WS_ENDPOINT + '?clientId=' + clientId + '&uuid=' + uuid + '&ageGroup=3');  
            wsConn.onmessage = this.onWebsocketMessage;  
            wsConn.onopen = () => {
                console.log('Websocket open');
                const wsReq = {
                    type: 'JOIN_GAME'
                };
                wsConn.send(JSON.stringify(wsReq));
            }
            return wsConn;
        },       
        onWebsocketMessage: function(e) {
            const msg = JSON.parse(e.data);  
            console.log(msg);
            /*if (msg.type === 'JOIN_GAME' && msg.success) {

            }*/
        }
    }
}
</script>