<template>
    <div>

        <div v-if="week">    
            <div class="font-semibold mb-2">Games played ({{totGameCount}})</div>    
            <input type="week" @change="setWeek" :value="week" class="mb-2">
            <GamesChart :week="week" :key="chartKey" v-on:count="setGameCount" />
        </div>

    </div>
</template>

<script>
import GamesChart from '@/components/GamesChart.vue';
import { getTodayHtml, currentWeek } from '@/utils.js';

export default {
    components: {
        GamesChart
    },
    data() {
        return {            
            chartKey: 0,
            week: '',
            totGameCount: 0,
        }
    },
    mounted() {
        const date = new Date();
        let week = currentWeek();
        if (week < 10) {
            week = '0' + week;
        }
        this.week = date.getFullYear() + '-W' + week;
    },
    methods: {
        getTodayHtml,
        setWeek: function(e) {
            this.week = e.target.value;
            this.chartKey++;
        },
        setGameCount: function(count) {
            this.totGameCount = count;
        }
    }
}
</script>